import React, { useMemo } from "react";

import type { AxiosResponse } from "axios";

import type { ExceptionSchema, UserSchema } from "@/api";
import { useLogout, useUserMe } from "@/api";

type AuthContextType = {
  user: UserSchema | undefined;
  userError?: AxiosResponse<ExceptionSchema>;
  logout: () => Promise<void>;
};

export const AuthContext = React.createContext<AuthContextType>(
  {} as AuthContextType,
);

export function AuthProvider({
  children,
  fallback,
}: {
  children: React.ReactNode;
  fallback: React.ReactNode;
}) {
  const { data: user, isLoading, error: userError } = useUserMe();
  const { mutateAsync: logout } = useLogout();

  const value = useMemo(() => ({ user, userError, logout }), [user, userError, logout]);

  if (isLoading) {
    return fallback;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
