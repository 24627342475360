import type { SubmitHandler, UseFormReturn } from "react-hook-form";

import { cn } from "@/lib/utils";

import type { ButtonProps } from "../ui/button";
import { Button } from "../ui/button";

type SubmitButtonProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: UseFormReturn<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit: SubmitHandler<any>;
    children: React.ReactNode;
    variant?: ButtonProps["variant"];
    className?: string;
}

export const SubmitButton = ({ form, children, onSubmit, variant, className }: SubmitButtonProps) => {
    return (
        <Button className={cn("mt-2", className)} disabled={form.formState.isSubmitting} size="xs" variant={variant} onClick={onSubmit}>
            {children}
        </Button>
    )
}
