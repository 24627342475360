import { useCallback } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowRightIcon } from "lucide-react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import type { z } from "zod";

import { authInternalSetupAccountBody, useAuthInternalSetupAccount } from "@/api";
import { FormError, FormInput, setFormError, SubmitButton } from "@/components/form";
import { Form } from "@/components/ui/form";

import { AuthLayout } from "../layout/AuthLayout";

const setupAccountBody = authInternalSetupAccountBody
  .refine((schema) => schema.password1 === schema.password2, {
    message: "Passwords do not match",
    path: ["password2"],
  });

export const AccountSetup = () => {
  const navigate = useNavigate()
  const { uid, token } = useParams();

  const form = useForm<z.infer<typeof setupAccountBody>>({
    resolver: zodResolver(setupAccountBody),
    defaultValues: {
      uid: uid!,
      token: token!,
      password1: undefined,
      password2: undefined,
    },
  });

  const { mutateAsync: setupAccount } = useAuthInternalSetupAccount();

  const handleSubmit = useCallback(
    async (data: z.infer<typeof setupAccountBody>) => {
      try {
        await setupAccount({ data })
        navigate("/auth/login/complete");
      } catch (error) {
        setFormError(form, error);
      }
    },
    [form, navigate, setupAccount],
  );

  return (
    <AuthLayout
      heading="Setup Your Account"
      subheading="Please enter a password for you account"
      title="Setup Your Account"
    >
      <Form {...form}>
        <div className="space-y-4">
          <FormError form={form} />
          <FormInput control={form.control} label="Password" name="password1" type="password" />
          <FormInput control={form.control} label="Re-enter Password" name="password2" type="password" />
          <SubmitButton
            className="w-full"
            form={form}
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            Set password
            <ArrowRightIcon className="ml-1.5 h-4 w-4" />
          </SubmitButton>
        </div>
      </Form>
      <Link
        className="text-xs w-full text-center"
        to="/auth/login"
      >
        Back to login
      </Link>
    </AuthLayout>
  )
}
