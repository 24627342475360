import { wrapUseRoutes } from "@sentry/react";
import { useRoutes } from "react-router-dom";

import { lazyImport } from "@/utils/lazyImport";

const useSentryRoutes = wrapUseRoutes(useRoutes);

const { AuthenticatonRoutes } = lazyImport(
  () => import("@/routes/workspaces/authentication"),
  "AuthenticatonRoutes",
);

const { ControlPanelRoutes } = lazyImport(
  () => import("@/routes/workspaces/control-panel"),
  "ControlPanelRoutes",
);

const { ContribRoutes } = lazyImport(
  () => import("@/routes/workspaces/contrib"),
  "ContribRoutes",
);

const routes = [
  { path: "/auth/*", element: <AuthenticatonRoutes /> },
  { path: "/control-panel/*", element: <ControlPanelRoutes /> },
  { path: "*", element: <ContribRoutes /> },
];

export const AppRoutes = () => {
  const element = useSentryRoutes(routes);

  return <>{element}</>;
};
