import { useCallback } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { FormError, FormInput, setFormError, SubmitButton } from "@/components/form";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Form } from "@/components/ui/form";

const formSchema = z
  .object({
    confirm: z.string().min(1, "Required"),
  })
  .refine((data) => data.confirm === "confirm", {
    message: `Please enter "confirm" to delete.`,
    path: ["confirm"],
  });

type DeleteModelProps = {
  title: string;
  subtitle: string;
  onDelete: () => Promise<void>;
  buttonText?: string;
};

export const DeleteModel = ({
  title,
  subtitle,
  onDelete,
  buttonText = "Permanently Delete"
}: DeleteModelProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      confirm: undefined
    }
  });

  const handleSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (_data: z.infer<typeof formSchema>) => {
      try {
        await onDelete()
      } catch (error) {
        setFormError(form, error);
      }
    }, [form, onDelete]);

  return (
    <DialogContent className="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription>{subtitle}</DialogDescription>
      </DialogHeader>
      <div className="flex flex-col">
        <FormError className="mb-4" form={form} />
        <Form {...form}>
          <div>
            <FormInput
              control={form.control}
              name="confirm"
              placeholder={`Enter "confirm" to delete`}
            />
            <div className="w-full mt-2 flex justify-end">
              <SubmitButton form={form} variant="destructive" onSubmit={form.handleSubmit(handleSubmit)}>
                {buttonText}
              </SubmitButton>
            </div>
          </div>

        </Form>
      </div>
    </DialogContent>
  );
};
