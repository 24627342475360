// @ts-nocheck
/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * NinjaAPI
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import type {
  ActivityCreateInputSerialiser,
  ActivityListParams,
  ActivitySchema,
  ActivityTypeCreateInputSerialiser,
  ActivityTypeSchema,
  ActivityTypeUpdateInputSerialiser,
  ActivityUpdateInputSerialiser,
  AuthTokenListForUserParams,
  BodySchema,
  ChangePasswordInputSerialiser,
  ControlPanelSearchParams,
  EventLogListForResourceParams,
  EventLogListForUserParams,
  ExceptionSchema,
  FileUploadFinishInputSerialiser,
  FileUploadLocalBody,
  FileUploadSchema,
  FileUploadStartInputSerialiser,
  FileUploadStartOutputSerialiser,
  ForgotPasswordInputSerialiser,
  GroupCreateInputSerialiser,
  GroupListParams,
  GroupSchema,
  GroupUpdateInputSerialiser,
  GroupUpdatePermissionsInputSerialiser,
  GroupUpdateUsersInputSerialiser,
  IntegrationInstanceCreateInputSerialiser,
  IntegrationInstanceExecutionListForIntegrationInstanceParams,
  IntegrationInstanceListParams,
  IntegrationInstanceSchema,
  IntegrationInstanceUpdateConfigInputSerialiser,
  IntegrationInstanceUpdateDetailsInputSerialiser,
  IntegrationSchema,
  LoginEmailPasswordInputSerialiser,
  MFATeamConfigurationSchema,
  MFATeamConfigurationUpdateInputSerialiser,
  MetricCreateInputSeraliser,
  MetricListParams,
  MetricSchema,
  MetricUpdateInputSeraliser,
  MfaGenerateUriOutputSerialiser,
  MfaVerifyOtpInputSerialiser,
  NetWorthCategoryCreateInputSerialiser,
  NetWorthCategorySchema,
  NetWorthCreateInputSerialiser,
  NetWorthSchema,
  PagedActivitySchema,
  PagedAnnotated,
  PagedAuthTokenMetadataSchema,
  PagedEventLogSchema,
  PagedIntegrationInstanceExecutionSchema,
  PagedIntegrationInstanceSchema,
  PagedPhysicalSnapshotSchema,
  PagedSecretSchema,
  PagedTeamSchema,
  PagedTransactionSchema,
  PagedUserAdminSchema,
  PagedUserSchema,
  Pagedstr,
  PermissionSchema,
  PhysicalSnapshotCreateInputSerialiser,
  PhysicalSnapshotListParams,
  PhysicalSnapshotMetricDataPointSchema,
  PhysicalSnapshotSchema,
  RealmResolveInputSerialiser,
  RealmResolveOutputSerialiser,
  RealmUpdateInputSerialiser,
  ReamlSchema,
  ResetPasswordInputSerialiser,
  SecretCreateInputSerialiser,
  SecretListParams,
  SecretSchema,
  SecretUpdateInputSerialiser,
  SetupAccountInputSerialiser,
  TeamCreateInputSerialiser,
  TeamListParams,
  TeamSchema,
  TeamUpdateInputSerialiser,
  TransactionAggregateFieldValuesParams,
  TransactionCategoryStatsParams,
  TransactionCategoryStatsSchema,
  TransactionListParams,
  TransactionOverallStatsParams,
  TransactionOverallStatsSchema,
  TransactionSchema,
  TransactionSpendingStatsSchema,
  TransactionUpdateInputSerialiser,
  UserAdminCreateInputSerialiser,
  UserAdminListParams,
  UserAdminSchema,
  UserAdminUpdateInputSerialiser,
  UserCreateInputSerialiser,
  UserListParams,
  UserSchema,
  UserUpdateInputSerialiser,
  WorkspaceDefinition,
  WorkspaceInputSerialiser,
  WorkspaceSchema
} from '.././model'
import { customInstance } from '../../lib/axios';
import type { ErrorType } from '../../lib/axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary Healthcheck Api
 */
export const healthcheck = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<void>(
      {url: `/api/healthcheck/`, method: 'GET', signal
    },
      options);
    }
  

export const getHealthcheckQueryKey = () => {
    return [`/api/healthcheck/`] as const;
    }

    
export const getHealthcheckQueryOptions = <TData = Awaited<ReturnType<typeof healthcheck>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof healthcheck>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getHealthcheckQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof healthcheck>>> = ({ signal }) => healthcheck(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof healthcheck>>, TError, TData> & { queryKey: QueryKey }
}

export type HealthcheckQueryResult = NonNullable<Awaited<ReturnType<typeof healthcheck>>>
export type HealthcheckQueryError = ErrorType<unknown>

/**
 * @summary Healthcheck Api
 */
export const useHealthcheck = <TData = Awaited<ReturnType<typeof healthcheck>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof healthcheck>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getHealthcheckQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary User List Api
 */
export const userList = (
    params?: UserListParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PagedUserSchema>(
      {url: `/api/users/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getUserListQueryKey = (params?: UserListParams,) => {
    return [`/api/users/`, ...(params ? [params]: [])] as const;
    }

    
export const getUserListQueryOptions = <TData = Awaited<ReturnType<typeof userList>>, TError = ErrorType<unknown>>(params?: UserListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userList>>> = ({ signal }) => userList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userList>>, TError, TData> & { queryKey: QueryKey }
}

export type UserListQueryResult = NonNullable<Awaited<ReturnType<typeof userList>>>
export type UserListQueryError = ErrorType<unknown>

/**
 * @summary User List Api
 */
export const useUserList = <TData = Awaited<ReturnType<typeof userList>>, TError = ErrorType<unknown>>(
 params?: UserListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary User Create Api
 */
export const userCreate = (
    userCreateInputSerialiser: UserCreateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<UserSchema>(
      {url: `/api/users/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userCreateInputSerialiser
    },
      options);
    }
  


export const getUserCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userCreate>>, TError,{data: UserCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof userCreate>>, TError,{data: UserCreateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userCreate>>, {data: UserCreateInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  userCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UserCreateMutationResult = NonNullable<Awaited<ReturnType<typeof userCreate>>>
    export type UserCreateMutationBody = UserCreateInputSerialiser
    export type UserCreateMutationError = ErrorType<unknown>

    /**
 * @summary User Create Api
 */
export const useUserCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userCreate>>, TError,{data: UserCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof userCreate>>,
        TError,
        {data: UserCreateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getUserCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary User Get Api
 */
export const userGet = (
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<UserSchema>(
      {url: `/api/users/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getUserGetQueryKey = (id: string,) => {
    return [`/api/users/${id}`] as const;
    }

    
export const getUserGetQueryOptions = <TData = Awaited<ReturnType<typeof userGet>>, TError = ErrorType<unknown>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserGetQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userGet>>> = ({ signal }) => userGet(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userGet>>, TError, TData> & { queryKey: QueryKey }
}

export type UserGetQueryResult = NonNullable<Awaited<ReturnType<typeof userGet>>>
export type UserGetQueryError = ErrorType<unknown>

/**
 * @summary User Get Api
 */
export const useUserGet = <TData = Awaited<ReturnType<typeof userGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserGetQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary User Update Api
 */
export const userUpdate = (
    id: string,
    userUpdateInputSerialiser: UserUpdateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<UserSchema>(
      {url: `/api/users/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: userUpdateInputSerialiser
    },
      options);
    }
  


export const getUserUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userUpdate>>, TError,{id: string;data: UserUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof userUpdate>>, TError,{id: string;data: UserUpdateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userUpdate>>, {id: string;data: UserUpdateInputSerialiser}> = (props) => {
          const {id,data} = props ?? {};

          return  userUpdate(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UserUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof userUpdate>>>
    export type UserUpdateMutationBody = UserUpdateInputSerialiser
    export type UserUpdateMutationError = ErrorType<unknown>

    /**
 * @summary User Update Api
 */
export const useUserUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userUpdate>>, TError,{id: string;data: UserUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof userUpdate>>,
        TError,
        {id: string;data: UserUpdateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getUserUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary User Delete Api
 */
export const userDelete = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/users/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getUserDeleteMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userDelete>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof userDelete>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  userDelete(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UserDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof userDelete>>>
    
    export type UserDeleteMutationError = ErrorType<unknown>

    /**
 * @summary User Delete Api
 */
export const useUserDelete = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userDelete>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof userDelete>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getUserDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary User Me Api
 */
export const userMe = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<UserSchema>(
      {url: `/api/users/me`, method: 'GET', signal
    },
      options);
    }
  

export const getUserMeQueryKey = () => {
    return [`/api/users/me`] as const;
    }

    
export const getUserMeQueryOptions = <TData = Awaited<ReturnType<typeof userMe>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userMe>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserMeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userMe>>> = ({ signal }) => userMe(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userMe>>, TError, TData> & { queryKey: QueryKey }
}

export type UserMeQueryResult = NonNullable<Awaited<ReturnType<typeof userMe>>>
export type UserMeQueryError = ErrorType<unknown>

/**
 * @summary User Me Api
 */
export const useUserMe = <TData = Awaited<ReturnType<typeof userMe>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userMe>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserMeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Permission List Api
 */
export const permissionList = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<string[]>(
      {url: `/api/permissions/`, method: 'GET', signal
    },
      options);
    }
  

export const getPermissionListQueryKey = () => {
    return [`/api/permissions/`] as const;
    }

    
export const getPermissionListQueryOptions = <TData = Awaited<ReturnType<typeof permissionList>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof permissionList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPermissionListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof permissionList>>> = ({ signal }) => permissionList(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof permissionList>>, TError, TData> & { queryKey: QueryKey }
}

export type PermissionListQueryResult = NonNullable<Awaited<ReturnType<typeof permissionList>>>
export type PermissionListQueryError = ErrorType<unknown>

/**
 * @summary Permission List Api
 */
export const usePermissionList = <TData = Awaited<ReturnType<typeof permissionList>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof permissionList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPermissionListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * When in production, the /media/ served by nginx. Traefik is configured to have a ForwardAuth
middleware that authenticates requests to /media/. This endpoint is used to check if the user
has access to the file.
 * @summary File Upload Check Access Api
 */
export const fileUploadCheckAccess = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<void>(
      {url: `/api/media/check-access/`, method: 'GET', signal
    },
      options);
    }
  

export const getFileUploadCheckAccessQueryKey = () => {
    return [`/api/media/check-access/`] as const;
    }

    
export const getFileUploadCheckAccessQueryOptions = <TData = Awaited<ReturnType<typeof fileUploadCheckAccess>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fileUploadCheckAccess>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFileUploadCheckAccessQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fileUploadCheckAccess>>> = ({ signal }) => fileUploadCheckAccess(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fileUploadCheckAccess>>, TError, TData> & { queryKey: QueryKey }
}

export type FileUploadCheckAccessQueryResult = NonNullable<Awaited<ReturnType<typeof fileUploadCheckAccess>>>
export type FileUploadCheckAccessQueryError = ErrorType<unknown>

/**
 * @summary File Upload Check Access Api
 */
export const useFileUploadCheckAccess = <TData = Awaited<ReturnType<typeof fileUploadCheckAccess>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fileUploadCheckAccess>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFileUploadCheckAccessQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary File Upload Start Api
 */
export const fileUploadStart = (
    fileUploadStartInputSerialiser: FileUploadStartInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<FileUploadStartOutputSerialiser>(
      {url: `/api/media/upload/start`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: fileUploadStartInputSerialiser
    },
      options);
    }
  


export const getFileUploadStartMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fileUploadStart>>, TError,{data: FileUploadStartInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof fileUploadStart>>, TError,{data: FileUploadStartInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof fileUploadStart>>, {data: FileUploadStartInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  fileUploadStart(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type FileUploadStartMutationResult = NonNullable<Awaited<ReturnType<typeof fileUploadStart>>>
    export type FileUploadStartMutationBody = FileUploadStartInputSerialiser
    export type FileUploadStartMutationError = ErrorType<unknown>

    /**
 * @summary File Upload Start Api
 */
export const useFileUploadStart = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fileUploadStart>>, TError,{data: FileUploadStartInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof fileUploadStart>>,
        TError,
        {data: FileUploadStartInputSerialiser},
        TContext
      > => {

      const mutationOptions = getFileUploadStartMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary File Upload Finish Api
 */
export const fileUploadFinish = (
    fileUploadFinishInputSerialiser: FileUploadFinishInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<FileUploadSchema>(
      {url: `/api/media/upload/finish`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: fileUploadFinishInputSerialiser
    },
      options);
    }
  


export const getFileUploadFinishMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fileUploadFinish>>, TError,{data: FileUploadFinishInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof fileUploadFinish>>, TError,{data: FileUploadFinishInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof fileUploadFinish>>, {data: FileUploadFinishInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  fileUploadFinish(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type FileUploadFinishMutationResult = NonNullable<Awaited<ReturnType<typeof fileUploadFinish>>>
    export type FileUploadFinishMutationBody = FileUploadFinishInputSerialiser
    export type FileUploadFinishMutationError = ErrorType<unknown>

    /**
 * @summary File Upload Finish Api
 */
export const useFileUploadFinish = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fileUploadFinish>>, TError,{data: FileUploadFinishInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof fileUploadFinish>>,
        TError,
        {data: FileUploadFinishInputSerialiser},
        TContext
      > => {

      const mutationOptions = getFileUploadFinishMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * This endpoint is used to upload a file to the local file system. It is used with the local
backend.
 * @summary File Upload Local Api
 */
export const fileUploadLocal = (
    fileId: string,
    fileUploadLocalBody: FileUploadLocalBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      const formData = new FormData();
formData.append('file', fileUploadLocalBody.file)

      return customInstance<FileUploadSchema>(
      {url: `/api/media/upload/local/${fileId}`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getFileUploadLocalMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fileUploadLocal>>, TError,{fileId: string;data: FileUploadLocalBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof fileUploadLocal>>, TError,{fileId: string;data: FileUploadLocalBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof fileUploadLocal>>, {fileId: string;data: FileUploadLocalBody}> = (props) => {
          const {fileId,data} = props ?? {};

          return  fileUploadLocal(fileId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type FileUploadLocalMutationResult = NonNullable<Awaited<ReturnType<typeof fileUploadLocal>>>
    export type FileUploadLocalMutationBody = FileUploadLocalBody
    export type FileUploadLocalMutationError = ErrorType<unknown>

    /**
 * @summary File Upload Local Api
 */
export const useFileUploadLocal = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fileUploadLocal>>, TError,{fileId: string;data: FileUploadLocalBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof fileUploadLocal>>,
        TError,
        {fileId: string;data: FileUploadLocalBody},
        TContext
      > => {

      const mutationOptions = getFileUploadLocalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the current user's team workspace definition.
 * @summary Workspace Get Api
 */
export const workspaceGet = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<WorkspaceDefinition>(
      {url: `/api/workspace/`, method: 'GET', signal
    },
      options);
    }
  

export const getWorkspaceGetQueryKey = () => {
    return [`/api/workspace/`] as const;
    }

    
export const getWorkspaceGetQueryOptions = <TData = Awaited<ReturnType<typeof workspaceGet>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof workspaceGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWorkspaceGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof workspaceGet>>> = ({ signal }) => workspaceGet(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof workspaceGet>>, TError, TData> & { queryKey: QueryKey }
}

export type WorkspaceGetQueryResult = NonNullable<Awaited<ReturnType<typeof workspaceGet>>>
export type WorkspaceGetQueryError = ErrorType<unknown>

/**
 * @summary Workspace Get Api
 */
export const useWorkspaceGet = <TData = Awaited<ReturnType<typeof workspaceGet>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof workspaceGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWorkspaceGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Log the user out.
 * @summary Logout Api
 */
export const logout = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/authentication/auth/logout`, method: 'POST'
    },
      options);
    }
  


export const getLogoutMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof logout>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof logout>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof logout>>, void> = () => {
          

          return  logout(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LogoutMutationResult = NonNullable<Awaited<ReturnType<typeof logout>>>
    
    export type LogoutMutationError = ErrorType<unknown>

    /**
 * @summary Logout Api
 */
export const useLogout = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof logout>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof logout>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getLogoutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * View for users to login via email/password.
 * @summary Auth Internal Login Api
 */
export const authInternalLogin = (
    loginEmailPasswordInputSerialiser: LoginEmailPasswordInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/authentication/auth/internal/login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: loginEmailPasswordInputSerialiser
    },
      options);
    }
  


export const getAuthInternalLoginMutationOptions = <TError = ErrorType<ExceptionSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authInternalLogin>>, TError,{data: LoginEmailPasswordInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof authInternalLogin>>, TError,{data: LoginEmailPasswordInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authInternalLogin>>, {data: LoginEmailPasswordInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  authInternalLogin(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AuthInternalLoginMutationResult = NonNullable<Awaited<ReturnType<typeof authInternalLogin>>>
    export type AuthInternalLoginMutationBody = LoginEmailPasswordInputSerialiser
    export type AuthInternalLoginMutationError = ErrorType<ExceptionSchema>

    /**
 * @summary Auth Internal Login Api
 */
export const useAuthInternalLogin = <TError = ErrorType<ExceptionSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authInternalLogin>>, TError,{data: LoginEmailPasswordInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof authInternalLogin>>,
        TError,
        {data: LoginEmailPasswordInputSerialiser},
        TContext
      > => {

      const mutationOptions = getAuthInternalLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * View for users to setup their account from the link sent via email.
 * @summary Auth Internal Setup Account Api
 */
export const authInternalSetupAccount = (
    setupAccountInputSerialiser: SetupAccountInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/authentication/auth/internal/setup-account`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: setupAccountInputSerialiser
    },
      options);
    }
  


export const getAuthInternalSetupAccountMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authInternalSetupAccount>>, TError,{data: SetupAccountInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof authInternalSetupAccount>>, TError,{data: SetupAccountInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authInternalSetupAccount>>, {data: SetupAccountInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  authInternalSetupAccount(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AuthInternalSetupAccountMutationResult = NonNullable<Awaited<ReturnType<typeof authInternalSetupAccount>>>
    export type AuthInternalSetupAccountMutationBody = SetupAccountInputSerialiser
    export type AuthInternalSetupAccountMutationError = ErrorType<unknown>

    /**
 * @summary Auth Internal Setup Account Api
 */
export const useAuthInternalSetupAccount = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authInternalSetupAccount>>, TError,{data: SetupAccountInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof authInternalSetupAccount>>,
        TError,
        {data: SetupAccountInputSerialiser},
        TContext
      > => {

      const mutationOptions = getAuthInternalSetupAccountMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * View for users to change their password.
 * @summary Auth Internal Change Password Api
 */
export const authInternalChangePassword = (
    changePasswordInputSerialiser: ChangePasswordInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/authentication/auth/internal/change-password`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: changePasswordInputSerialiser
    },
      options);
    }
  


export const getAuthInternalChangePasswordMutationOptions = <TError = ErrorType<ExceptionSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authInternalChangePassword>>, TError,{data: ChangePasswordInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof authInternalChangePassword>>, TError,{data: ChangePasswordInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authInternalChangePassword>>, {data: ChangePasswordInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  authInternalChangePassword(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AuthInternalChangePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof authInternalChangePassword>>>
    export type AuthInternalChangePasswordMutationBody = ChangePasswordInputSerialiser
    export type AuthInternalChangePasswordMutationError = ErrorType<ExceptionSchema>

    /**
 * @summary Auth Internal Change Password Api
 */
export const useAuthInternalChangePassword = <TError = ErrorType<ExceptionSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authInternalChangePassword>>, TError,{data: ChangePasswordInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof authInternalChangePassword>>,
        TError,
        {data: ChangePasswordInputSerialiser},
        TContext
      > => {

      const mutationOptions = getAuthInternalChangePasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * View for users to be sent an email with a link to reset their password.
 * @summary Auth Internal Forgot Password Api
 */
export const authInternalForgotPassword = (
    forgotPasswordInputSerialiser: ForgotPasswordInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/authentication/auth/internal/forgot-password`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: forgotPasswordInputSerialiser
    },
      options);
    }
  


export const getAuthInternalForgotPasswordMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authInternalForgotPassword>>, TError,{data: ForgotPasswordInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof authInternalForgotPassword>>, TError,{data: ForgotPasswordInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authInternalForgotPassword>>, {data: ForgotPasswordInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  authInternalForgotPassword(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AuthInternalForgotPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof authInternalForgotPassword>>>
    export type AuthInternalForgotPasswordMutationBody = ForgotPasswordInputSerialiser
    export type AuthInternalForgotPasswordMutationError = ErrorType<unknown>

    /**
 * @summary Auth Internal Forgot Password Api
 */
export const useAuthInternalForgotPassword = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authInternalForgotPassword>>, TError,{data: ForgotPasswordInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof authInternalForgotPassword>>,
        TError,
        {data: ForgotPasswordInputSerialiser},
        TContext
      > => {

      const mutationOptions = getAuthInternalForgotPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * View for users to reset their password from a link sent via email.
 * @summary Auth Internal Reset Password Api
 */
export const authInternalResetPassword = (
    resetPasswordInputSerialiser: ResetPasswordInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/authentication/auth/internal/reset-password`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: resetPasswordInputSerialiser
    },
      options);
    }
  


export const getAuthInternalResetPasswordMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authInternalResetPassword>>, TError,{data: ResetPasswordInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof authInternalResetPassword>>, TError,{data: ResetPasswordInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authInternalResetPassword>>, {data: ResetPasswordInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  authInternalResetPassword(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AuthInternalResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof authInternalResetPassword>>>
    export type AuthInternalResetPasswordMutationBody = ResetPasswordInputSerialiser
    export type AuthInternalResetPasswordMutationError = ErrorType<unknown>

    /**
 * @summary Auth Internal Reset Password Api
 */
export const useAuthInternalResetPassword = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authInternalResetPassword>>, TError,{data: ResetPasswordInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof authInternalResetPassword>>,
        TError,
        {data: ResetPasswordInputSerialiser},
        TContext
      > => {

      const mutationOptions = getAuthInternalResetPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Return the URI for setting up multi-factor authentication.
 * @summary Mfa Generate Uri Api
 */
export const mfaGenerateUri = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<MfaGenerateUriOutputSerialiser>(
      {url: `/api/authentication/mfa/generate-uri`, method: 'GET', signal
    },
      options);
    }
  

export const getMfaGenerateUriQueryKey = () => {
    return [`/api/authentication/mfa/generate-uri`] as const;
    }

    
export const getMfaGenerateUriQueryOptions = <TData = Awaited<ReturnType<typeof mfaGenerateUri>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof mfaGenerateUri>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMfaGenerateUriQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof mfaGenerateUri>>> = ({ signal }) => mfaGenerateUri(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof mfaGenerateUri>>, TError, TData> & { queryKey: QueryKey }
}

export type MfaGenerateUriQueryResult = NonNullable<Awaited<ReturnType<typeof mfaGenerateUri>>>
export type MfaGenerateUriQueryError = ErrorType<unknown>

/**
 * @summary Mfa Generate Uri Api
 */
export const useMfaGenerateUri = <TData = Awaited<ReturnType<typeof mfaGenerateUri>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof mfaGenerateUri>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getMfaGenerateUriQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Verify the otp is valid for the user.
 * @summary Mda Verify Otp Api
 */
export const mfaVerifyOtp = (
    mfaVerifyOtpInputSerialiser: MfaVerifyOtpInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/authentication/mfa/verify-otp`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: mfaVerifyOtpInputSerialiser
    },
      options);
    }
  


export const getMfaVerifyOtpMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof mfaVerifyOtp>>, TError,{data: MfaVerifyOtpInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof mfaVerifyOtp>>, TError,{data: MfaVerifyOtpInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof mfaVerifyOtp>>, {data: MfaVerifyOtpInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  mfaVerifyOtp(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type MfaVerifyOtpMutationResult = NonNullable<Awaited<ReturnType<typeof mfaVerifyOtp>>>
    export type MfaVerifyOtpMutationBody = MfaVerifyOtpInputSerialiser
    export type MfaVerifyOtpMutationError = ErrorType<unknown>

    /**
 * @summary Mda Verify Otp Api
 */
export const useMfaVerifyOtp = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof mfaVerifyOtp>>, TError,{data: MfaVerifyOtpInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof mfaVerifyOtp>>,
        TError,
        {data: MfaVerifyOtpInputSerialiser},
        TContext
      > => {

      const mutationOptions = getMfaVerifyOtpMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Return the AuthenticationRealm for the given email.
 * @summary Realm Resolve Api
 */
export const realmResolve = (
    realmResolveInputSerialiser: RealmResolveInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<RealmResolveOutputSerialiser>(
      {url: `/api/authentication/realm/resolve`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: realmResolveInputSerialiser
    },
      options);
    }
  


export const getRealmResolveMutationOptions = <TError = ErrorType<ExceptionSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof realmResolve>>, TError,{data: RealmResolveInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof realmResolve>>, TError,{data: RealmResolveInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof realmResolve>>, {data: RealmResolveInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  realmResolve(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RealmResolveMutationResult = NonNullable<Awaited<ReturnType<typeof realmResolve>>>
    export type RealmResolveMutationBody = RealmResolveInputSerialiser
    export type RealmResolveMutationError = ErrorType<ExceptionSchema>

    /**
 * @summary Realm Resolve Api
 */
export const useRealmResolve = <TError = ErrorType<ExceptionSchema>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof realmResolve>>, TError,{data: RealmResolveInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof realmResolve>>,
        TError,
        {data: RealmResolveInputSerialiser},
        TContext
      > => {

      const mutationOptions = getRealmResolveMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update a Realm's configuration.
 * @summary Realm Update Configuration Api
 */
export const realmUpdateConfiguration = (
    realmId: string,
    realmUpdateInputSerialiser: RealmUpdateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ReamlSchema>(
      {url: `/api/authentication/realm/${realmId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: realmUpdateInputSerialiser
    },
      options);
    }
  


export const getRealmUpdateConfigurationMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof realmUpdateConfiguration>>, TError,{realmId: string;data: RealmUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof realmUpdateConfiguration>>, TError,{realmId: string;data: RealmUpdateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof realmUpdateConfiguration>>, {realmId: string;data: RealmUpdateInputSerialiser}> = (props) => {
          const {realmId,data} = props ?? {};

          return  realmUpdateConfiguration(realmId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RealmUpdateConfigurationMutationResult = NonNullable<Awaited<ReturnType<typeof realmUpdateConfiguration>>>
    export type RealmUpdateConfigurationMutationBody = RealmUpdateInputSerialiser
    export type RealmUpdateConfigurationMutationError = ErrorType<unknown>

    /**
 * @summary Realm Update Configuration Api
 */
export const useRealmUpdateConfiguration = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof realmUpdateConfiguration>>, TError,{realmId: string;data: RealmUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof realmUpdateConfiguration>>,
        TError,
        {realmId: string;data: RealmUpdateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getRealmUpdateConfigurationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Net Worth List Api
 */
export const netWorthList = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<NetWorthSchema[]>(
      {url: `/api/finance/net-worth/`, method: 'GET', signal
    },
      options);
    }
  

export const getNetWorthListQueryKey = () => {
    return [`/api/finance/net-worth/`] as const;
    }

    
export const getNetWorthListQueryOptions = <TData = Awaited<ReturnType<typeof netWorthList>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof netWorthList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getNetWorthListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof netWorthList>>> = ({ signal }) => netWorthList(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof netWorthList>>, TError, TData> & { queryKey: QueryKey }
}

export type NetWorthListQueryResult = NonNullable<Awaited<ReturnType<typeof netWorthList>>>
export type NetWorthListQueryError = ErrorType<unknown>

/**
 * @summary Net Worth List Api
 */
export const useNetWorthList = <TData = Awaited<ReturnType<typeof netWorthList>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof netWorthList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getNetWorthListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Net Worth Create Api
 */
export const netWorthCreate = (
    netWorthCreateInputSerialiser: NetWorthCreateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<NetWorthSchema>(
      {url: `/api/finance/net-worth/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: netWorthCreateInputSerialiser
    },
      options);
    }
  


export const getNetWorthCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof netWorthCreate>>, TError,{data: NetWorthCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof netWorthCreate>>, TError,{data: NetWorthCreateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof netWorthCreate>>, {data: NetWorthCreateInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  netWorthCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type NetWorthCreateMutationResult = NonNullable<Awaited<ReturnType<typeof netWorthCreate>>>
    export type NetWorthCreateMutationBody = NetWorthCreateInputSerialiser
    export type NetWorthCreateMutationError = ErrorType<unknown>

    /**
 * @summary Net Worth Create Api
 */
export const useNetWorthCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof netWorthCreate>>, TError,{data: NetWorthCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof netWorthCreate>>,
        TError,
        {data: NetWorthCreateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getNetWorthCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Net Worth Lsit Api
 */
export const netWorthCategoryList = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<NetWorthCategorySchema[]>(
      {url: `/api/finance/net-worth/categories/`, method: 'GET', signal
    },
      options);
    }
  

export const getNetWorthCategoryListQueryKey = () => {
    return [`/api/finance/net-worth/categories/`] as const;
    }

    
export const getNetWorthCategoryListQueryOptions = <TData = Awaited<ReturnType<typeof netWorthCategoryList>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof netWorthCategoryList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getNetWorthCategoryListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof netWorthCategoryList>>> = ({ signal }) => netWorthCategoryList(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof netWorthCategoryList>>, TError, TData> & { queryKey: QueryKey }
}

export type NetWorthCategoryListQueryResult = NonNullable<Awaited<ReturnType<typeof netWorthCategoryList>>>
export type NetWorthCategoryListQueryError = ErrorType<unknown>

/**
 * @summary Net Worth Lsit Api
 */
export const useNetWorthCategoryList = <TData = Awaited<ReturnType<typeof netWorthCategoryList>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof netWorthCategoryList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getNetWorthCategoryListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Net Worth Create Api
 */
export const netWorthCategoryCreate = (
    netWorthCategoryCreateInputSerialiser: NetWorthCategoryCreateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<NetWorthCategorySchema>(
      {url: `/api/finance/net-worth/categories/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: netWorthCategoryCreateInputSerialiser
    },
      options);
    }
  


export const getNetWorthCategoryCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof netWorthCategoryCreate>>, TError,{data: NetWorthCategoryCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof netWorthCategoryCreate>>, TError,{data: NetWorthCategoryCreateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof netWorthCategoryCreate>>, {data: NetWorthCategoryCreateInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  netWorthCategoryCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type NetWorthCategoryCreateMutationResult = NonNullable<Awaited<ReturnType<typeof netWorthCategoryCreate>>>
    export type NetWorthCategoryCreateMutationBody = NetWorthCategoryCreateInputSerialiser
    export type NetWorthCategoryCreateMutationError = ErrorType<unknown>

    /**
 * @summary Net Worth Create Api
 */
export const useNetWorthCategoryCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof netWorthCategoryCreate>>, TError,{data: NetWorthCategoryCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof netWorthCategoryCreate>>,
        TError,
        {data: NetWorthCategoryCreateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getNetWorthCategoryCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Transaction List Api
 */
export const transactionList = (
    params?: TransactionListParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PagedTransactionSchema>(
      {url: `/api/finance/transactions/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getTransactionListQueryKey = (params?: TransactionListParams,) => {
    return [`/api/finance/transactions/`, ...(params ? [params]: [])] as const;
    }

    
export const getTransactionListQueryOptions = <TData = Awaited<ReturnType<typeof transactionList>>, TError = ErrorType<unknown>>(params?: TransactionListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof transactionList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTransactionListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof transactionList>>> = ({ signal }) => transactionList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof transactionList>>, TError, TData> & { queryKey: QueryKey }
}

export type TransactionListQueryResult = NonNullable<Awaited<ReturnType<typeof transactionList>>>
export type TransactionListQueryError = ErrorType<unknown>

/**
 * @summary Transaction List Api
 */
export const useTransactionList = <TData = Awaited<ReturnType<typeof transactionList>>, TError = ErrorType<unknown>>(
 params?: TransactionListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof transactionList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getTransactionListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Transaction Overall Stats Api
 */
export const transactionOverallStats = (
    params?: TransactionOverallStatsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TransactionOverallStatsSchema>(
      {url: `/api/finance/transactions/overall-stats`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getTransactionOverallStatsQueryKey = (params?: TransactionOverallStatsParams,) => {
    return [`/api/finance/transactions/overall-stats`, ...(params ? [params]: [])] as const;
    }

    
export const getTransactionOverallStatsQueryOptions = <TData = Awaited<ReturnType<typeof transactionOverallStats>>, TError = ErrorType<unknown>>(params?: TransactionOverallStatsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof transactionOverallStats>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTransactionOverallStatsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof transactionOverallStats>>> = ({ signal }) => transactionOverallStats(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof transactionOverallStats>>, TError, TData> & { queryKey: QueryKey }
}

export type TransactionOverallStatsQueryResult = NonNullable<Awaited<ReturnType<typeof transactionOverallStats>>>
export type TransactionOverallStatsQueryError = ErrorType<unknown>

/**
 * @summary Transaction Overall Stats Api
 */
export const useTransactionOverallStats = <TData = Awaited<ReturnType<typeof transactionOverallStats>>, TError = ErrorType<unknown>>(
 params?: TransactionOverallStatsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof transactionOverallStats>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getTransactionOverallStatsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Transaction Spending Stats Api
 */
export const transactionSpendingStats = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TransactionSpendingStatsSchema>(
      {url: `/api/finance/transactions/spending-stats`, method: 'GET', signal
    },
      options);
    }
  

export const getTransactionSpendingStatsQueryKey = () => {
    return [`/api/finance/transactions/spending-stats`] as const;
    }

    
export const getTransactionSpendingStatsQueryOptions = <TData = Awaited<ReturnType<typeof transactionSpendingStats>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof transactionSpendingStats>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTransactionSpendingStatsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof transactionSpendingStats>>> = ({ signal }) => transactionSpendingStats(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof transactionSpendingStats>>, TError, TData> & { queryKey: QueryKey }
}

export type TransactionSpendingStatsQueryResult = NonNullable<Awaited<ReturnType<typeof transactionSpendingStats>>>
export type TransactionSpendingStatsQueryError = ErrorType<unknown>

/**
 * @summary Transaction Spending Stats Api
 */
export const useTransactionSpendingStats = <TData = Awaited<ReturnType<typeof transactionSpendingStats>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof transactionSpendingStats>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getTransactionSpendingStatsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Transaction Category Stats Api
 */
export const transactionCategoryStats = (
    params?: TransactionCategoryStatsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TransactionCategoryStatsSchema[]>(
      {url: `/api/finance/transactions/category-stats`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getTransactionCategoryStatsQueryKey = (params?: TransactionCategoryStatsParams,) => {
    return [`/api/finance/transactions/category-stats`, ...(params ? [params]: [])] as const;
    }

    
export const getTransactionCategoryStatsQueryOptions = <TData = Awaited<ReturnType<typeof transactionCategoryStats>>, TError = ErrorType<unknown>>(params?: TransactionCategoryStatsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof transactionCategoryStats>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTransactionCategoryStatsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof transactionCategoryStats>>> = ({ signal }) => transactionCategoryStats(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof transactionCategoryStats>>, TError, TData> & { queryKey: QueryKey }
}

export type TransactionCategoryStatsQueryResult = NonNullable<Awaited<ReturnType<typeof transactionCategoryStats>>>
export type TransactionCategoryStatsQueryError = ErrorType<unknown>

/**
 * @summary Transaction Category Stats Api
 */
export const useTransactionCategoryStats = <TData = Awaited<ReturnType<typeof transactionCategoryStats>>, TError = ErrorType<unknown>>(
 params?: TransactionCategoryStatsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof transactionCategoryStats>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getTransactionCategoryStatsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Transaction Get Api
 */
export const transactionGet = (
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TransactionSchema>(
      {url: `/api/finance/transactions/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getTransactionGetQueryKey = (id: string,) => {
    return [`/api/finance/transactions/${id}`] as const;
    }

    
export const getTransactionGetQueryOptions = <TData = Awaited<ReturnType<typeof transactionGet>>, TError = ErrorType<unknown>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof transactionGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTransactionGetQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof transactionGet>>> = ({ signal }) => transactionGet(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof transactionGet>>, TError, TData> & { queryKey: QueryKey }
}

export type TransactionGetQueryResult = NonNullable<Awaited<ReturnType<typeof transactionGet>>>
export type TransactionGetQueryError = ErrorType<unknown>

/**
 * @summary Transaction Get Api
 */
export const useTransactionGet = <TData = Awaited<ReturnType<typeof transactionGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof transactionGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getTransactionGetQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Transaction Update Api
 */
export const transactionUpdate = (
    id: string,
    transactionUpdateInputSerialiser: TransactionUpdateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<TransactionSchema>(
      {url: `/api/finance/transactions/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: transactionUpdateInputSerialiser
    },
      options);
    }
  


export const getTransactionUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof transactionUpdate>>, TError,{id: string;data: TransactionUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof transactionUpdate>>, TError,{id: string;data: TransactionUpdateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof transactionUpdate>>, {id: string;data: TransactionUpdateInputSerialiser}> = (props) => {
          const {id,data} = props ?? {};

          return  transactionUpdate(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TransactionUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof transactionUpdate>>>
    export type TransactionUpdateMutationBody = TransactionUpdateInputSerialiser
    export type TransactionUpdateMutationError = ErrorType<unknown>

    /**
 * @summary Transaction Update Api
 */
export const useTransactionUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof transactionUpdate>>, TError,{id: string;data: TransactionUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof transactionUpdate>>,
        TError,
        {id: string;data: TransactionUpdateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getTransactionUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the distinct values of a property on the Transaction model.
 * @summary Transaction Aggregate Field Values Api
 */
export const transactionAggregateFieldValues = (
    fieldName: string,
    params?: TransactionAggregateFieldValuesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<Pagedstr>(
      {url: `/api/finance/transactions/aggregate/${fieldName}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getTransactionAggregateFieldValuesQueryKey = (fieldName: string,
    params?: TransactionAggregateFieldValuesParams,) => {
    return [`/api/finance/transactions/aggregate/${fieldName}`, ...(params ? [params]: [])] as const;
    }

    
export const getTransactionAggregateFieldValuesQueryOptions = <TData = Awaited<ReturnType<typeof transactionAggregateFieldValues>>, TError = ErrorType<unknown>>(fieldName: string,
    params?: TransactionAggregateFieldValuesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof transactionAggregateFieldValues>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTransactionAggregateFieldValuesQueryKey(fieldName,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof transactionAggregateFieldValues>>> = ({ signal }) => transactionAggregateFieldValues(fieldName,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(fieldName), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof transactionAggregateFieldValues>>, TError, TData> & { queryKey: QueryKey }
}

export type TransactionAggregateFieldValuesQueryResult = NonNullable<Awaited<ReturnType<typeof transactionAggregateFieldValues>>>
export type TransactionAggregateFieldValuesQueryError = ErrorType<unknown>

/**
 * @summary Transaction Aggregate Field Values Api
 */
export const useTransactionAggregateFieldValues = <TData = Awaited<ReturnType<typeof transactionAggregateFieldValues>>, TError = ErrorType<unknown>>(
 fieldName: string,
    params?: TransactionAggregateFieldValuesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof transactionAggregateFieldValues>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getTransactionAggregateFieldValuesQueryOptions(fieldName,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Activity List Api
 */
export const activityList = (
    params?: ActivityListParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PagedActivitySchema>(
      {url: `/api/fitness/activities/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getActivityListQueryKey = (params?: ActivityListParams,) => {
    return [`/api/fitness/activities/`, ...(params ? [params]: [])] as const;
    }

    
export const getActivityListQueryOptions = <TData = Awaited<ReturnType<typeof activityList>>, TError = ErrorType<unknown>>(params?: ActivityListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof activityList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getActivityListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof activityList>>> = ({ signal }) => activityList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof activityList>>, TError, TData> & { queryKey: QueryKey }
}

export type ActivityListQueryResult = NonNullable<Awaited<ReturnType<typeof activityList>>>
export type ActivityListQueryError = ErrorType<unknown>

/**
 * @summary Activity List Api
 */
export const useActivityList = <TData = Awaited<ReturnType<typeof activityList>>, TError = ErrorType<unknown>>(
 params?: ActivityListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof activityList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getActivityListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Activity Create Api
 */
export const activityCreate = (
    activityCreateInputSerialiser: ActivityCreateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ActivitySchema>(
      {url: `/api/fitness/activities/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: activityCreateInputSerialiser
    },
      options);
    }
  


export const getActivityCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activityCreate>>, TError,{data: ActivityCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof activityCreate>>, TError,{data: ActivityCreateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof activityCreate>>, {data: ActivityCreateInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  activityCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ActivityCreateMutationResult = NonNullable<Awaited<ReturnType<typeof activityCreate>>>
    export type ActivityCreateMutationBody = ActivityCreateInputSerialiser
    export type ActivityCreateMutationError = ErrorType<unknown>

    /**
 * @summary Activity Create Api
 */
export const useActivityCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activityCreate>>, TError,{data: ActivityCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof activityCreate>>,
        TError,
        {data: ActivityCreateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getActivityCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Activity Get Api
 */
export const activityGet = (
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ActivitySchema>(
      {url: `/api/fitness/activities/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getActivityGetQueryKey = (id: string,) => {
    return [`/api/fitness/activities/${id}`] as const;
    }

    
export const getActivityGetQueryOptions = <TData = Awaited<ReturnType<typeof activityGet>>, TError = ErrorType<unknown>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof activityGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getActivityGetQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof activityGet>>> = ({ signal }) => activityGet(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof activityGet>>, TError, TData> & { queryKey: QueryKey }
}

export type ActivityGetQueryResult = NonNullable<Awaited<ReturnType<typeof activityGet>>>
export type ActivityGetQueryError = ErrorType<unknown>

/**
 * @summary Activity Get Api
 */
export const useActivityGet = <TData = Awaited<ReturnType<typeof activityGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof activityGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getActivityGetQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Activity Update Api
 */
export const activityUpdate = (
    id: string,
    activityUpdateInputSerialiser: ActivityUpdateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ActivitySchema>(
      {url: `/api/fitness/activities/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: activityUpdateInputSerialiser
    },
      options);
    }
  


export const getActivityUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activityUpdate>>, TError,{id: string;data: ActivityUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof activityUpdate>>, TError,{id: string;data: ActivityUpdateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof activityUpdate>>, {id: string;data: ActivityUpdateInputSerialiser}> = (props) => {
          const {id,data} = props ?? {};

          return  activityUpdate(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ActivityUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof activityUpdate>>>
    export type ActivityUpdateMutationBody = ActivityUpdateInputSerialiser
    export type ActivityUpdateMutationError = ErrorType<unknown>

    /**
 * @summary Activity Update Api
 */
export const useActivityUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activityUpdate>>, TError,{id: string;data: ActivityUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof activityUpdate>>,
        TError,
        {id: string;data: ActivityUpdateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getActivityUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Activity Delete Api
 */
export const activityDelete = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/fitness/activities/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getActivityDeleteMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activityDelete>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof activityDelete>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof activityDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  activityDelete(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ActivityDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof activityDelete>>>
    
    export type ActivityDeleteMutationError = ErrorType<unknown>

    /**
 * @summary Activity Delete Api
 */
export const useActivityDelete = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activityDelete>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof activityDelete>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getActivityDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Activity Type List Api
 */
export const activityTypeList = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ActivityTypeSchema[]>(
      {url: `/api/fitness/activity-types/`, method: 'GET', signal
    },
      options);
    }
  

export const getActivityTypeListQueryKey = () => {
    return [`/api/fitness/activity-types/`] as const;
    }

    
export const getActivityTypeListQueryOptions = <TData = Awaited<ReturnType<typeof activityTypeList>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof activityTypeList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getActivityTypeListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof activityTypeList>>> = ({ signal }) => activityTypeList(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof activityTypeList>>, TError, TData> & { queryKey: QueryKey }
}

export type ActivityTypeListQueryResult = NonNullable<Awaited<ReturnType<typeof activityTypeList>>>
export type ActivityTypeListQueryError = ErrorType<unknown>

/**
 * @summary Activity Type List Api
 */
export const useActivityTypeList = <TData = Awaited<ReturnType<typeof activityTypeList>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof activityTypeList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getActivityTypeListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Activity Type Create Api
 */
export const activityTypeCreate = (
    activityTypeCreateInputSerialiser: ActivityTypeCreateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ActivityTypeSchema>(
      {url: `/api/fitness/activity-types/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: activityTypeCreateInputSerialiser
    },
      options);
    }
  


export const getActivityTypeCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activityTypeCreate>>, TError,{data: ActivityTypeCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof activityTypeCreate>>, TError,{data: ActivityTypeCreateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof activityTypeCreate>>, {data: ActivityTypeCreateInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  activityTypeCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ActivityTypeCreateMutationResult = NonNullable<Awaited<ReturnType<typeof activityTypeCreate>>>
    export type ActivityTypeCreateMutationBody = ActivityTypeCreateInputSerialiser
    export type ActivityTypeCreateMutationError = ErrorType<unknown>

    /**
 * @summary Activity Type Create Api
 */
export const useActivityTypeCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activityTypeCreate>>, TError,{data: ActivityTypeCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof activityTypeCreate>>,
        TError,
        {data: ActivityTypeCreateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getActivityTypeCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Activity Type Update Api
 */
export const activityTypeUpdate = (
    id: string,
    activityTypeUpdateInputSerialiser: ActivityTypeUpdateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<ActivityTypeSchema>(
      {url: `/api/fitness/activity-types/${id}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: activityTypeUpdateInputSerialiser
    },
      options);
    }
  


export const getActivityTypeUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activityTypeUpdate>>, TError,{id: string;data: ActivityTypeUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof activityTypeUpdate>>, TError,{id: string;data: ActivityTypeUpdateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof activityTypeUpdate>>, {id: string;data: ActivityTypeUpdateInputSerialiser}> = (props) => {
          const {id,data} = props ?? {};

          return  activityTypeUpdate(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ActivityTypeUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof activityTypeUpdate>>>
    export type ActivityTypeUpdateMutationBody = ActivityTypeUpdateInputSerialiser
    export type ActivityTypeUpdateMutationError = ErrorType<unknown>

    /**
 * @summary Activity Type Update Api
 */
export const useActivityTypeUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activityTypeUpdate>>, TError,{id: string;data: ActivityTypeUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof activityTypeUpdate>>,
        TError,
        {id: string;data: ActivityTypeUpdateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getActivityTypeUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Physical Snapshot List Api
 */
export const physicalSnapshotList = (
    params?: PhysicalSnapshotListParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PagedPhysicalSnapshotSchema>(
      {url: `/api/fitness/physical-snapshots/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getPhysicalSnapshotListQueryKey = (params?: PhysicalSnapshotListParams,) => {
    return [`/api/fitness/physical-snapshots/`, ...(params ? [params]: [])] as const;
    }

    
export const getPhysicalSnapshotListQueryOptions = <TData = Awaited<ReturnType<typeof physicalSnapshotList>>, TError = ErrorType<unknown>>(params?: PhysicalSnapshotListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof physicalSnapshotList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPhysicalSnapshotListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof physicalSnapshotList>>> = ({ signal }) => physicalSnapshotList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof physicalSnapshotList>>, TError, TData> & { queryKey: QueryKey }
}

export type PhysicalSnapshotListQueryResult = NonNullable<Awaited<ReturnType<typeof physicalSnapshotList>>>
export type PhysicalSnapshotListQueryError = ErrorType<unknown>

/**
 * @summary Physical Snapshot List Api
 */
export const usePhysicalSnapshotList = <TData = Awaited<ReturnType<typeof physicalSnapshotList>>, TError = ErrorType<unknown>>(
 params?: PhysicalSnapshotListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof physicalSnapshotList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPhysicalSnapshotListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Physical Snapshot Create Api
 */
export const physicalSnapshotCreate = (
    physicalSnapshotCreateInputSerialiser: PhysicalSnapshotCreateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PhysicalSnapshotSchema>(
      {url: `/api/fitness/physical-snapshots/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: physicalSnapshotCreateInputSerialiser
    },
      options);
    }
  


export const getPhysicalSnapshotCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof physicalSnapshotCreate>>, TError,{data: PhysicalSnapshotCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof physicalSnapshotCreate>>, TError,{data: PhysicalSnapshotCreateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof physicalSnapshotCreate>>, {data: PhysicalSnapshotCreateInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  physicalSnapshotCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PhysicalSnapshotCreateMutationResult = NonNullable<Awaited<ReturnType<typeof physicalSnapshotCreate>>>
    export type PhysicalSnapshotCreateMutationBody = PhysicalSnapshotCreateInputSerialiser
    export type PhysicalSnapshotCreateMutationError = ErrorType<unknown>

    /**
 * @summary Physical Snapshot Create Api
 */
export const usePhysicalSnapshotCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof physicalSnapshotCreate>>, TError,{data: PhysicalSnapshotCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof physicalSnapshotCreate>>,
        TError,
        {data: PhysicalSnapshotCreateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getPhysicalSnapshotCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Physical Snapshot Get Api
 */
export const physicalSnapshotGet = (
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PhysicalSnapshotSchema>(
      {url: `/api/fitness/physical-snapshots/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getPhysicalSnapshotGetQueryKey = (id: string,) => {
    return [`/api/fitness/physical-snapshots/${id}`] as const;
    }

    
export const getPhysicalSnapshotGetQueryOptions = <TData = Awaited<ReturnType<typeof physicalSnapshotGet>>, TError = ErrorType<unknown>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof physicalSnapshotGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPhysicalSnapshotGetQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof physicalSnapshotGet>>> = ({ signal }) => physicalSnapshotGet(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof physicalSnapshotGet>>, TError, TData> & { queryKey: QueryKey }
}

export type PhysicalSnapshotGetQueryResult = NonNullable<Awaited<ReturnType<typeof physicalSnapshotGet>>>
export type PhysicalSnapshotGetQueryError = ErrorType<unknown>

/**
 * @summary Physical Snapshot Get Api
 */
export const usePhysicalSnapshotGet = <TData = Awaited<ReturnType<typeof physicalSnapshotGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof physicalSnapshotGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPhysicalSnapshotGetQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Physical Snapshot Delete Api
 */
export const physicalSnapshotDelete = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/fitness/physical-snapshots/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getPhysicalSnapshotDeleteMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof physicalSnapshotDelete>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof physicalSnapshotDelete>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof physicalSnapshotDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  physicalSnapshotDelete(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PhysicalSnapshotDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof physicalSnapshotDelete>>>
    
    export type PhysicalSnapshotDeleteMutationError = ErrorType<unknown>

    /**
 * @summary Physical Snapshot Delete Api
 */
export const usePhysicalSnapshotDelete = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof physicalSnapshotDelete>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof physicalSnapshotDelete>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getPhysicalSnapshotDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Return all all of the metrics with
 * @summary Physical Snapshot Metric Stats Api
 */
export const physicalSnapshotMetricStats = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PhysicalSnapshotMetricDataPointSchema[]>(
      {url: `/api/fitness/physical-snapshots/metrics/`, method: 'GET', signal
    },
      options);
    }
  

export const getPhysicalSnapshotMetricStatsQueryKey = () => {
    return [`/api/fitness/physical-snapshots/metrics/`] as const;
    }

    
export const getPhysicalSnapshotMetricStatsQueryOptions = <TData = Awaited<ReturnType<typeof physicalSnapshotMetricStats>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof physicalSnapshotMetricStats>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPhysicalSnapshotMetricStatsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof physicalSnapshotMetricStats>>> = ({ signal }) => physicalSnapshotMetricStats(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof physicalSnapshotMetricStats>>, TError, TData> & { queryKey: QueryKey }
}

export type PhysicalSnapshotMetricStatsQueryResult = NonNullable<Awaited<ReturnType<typeof physicalSnapshotMetricStats>>>
export type PhysicalSnapshotMetricStatsQueryError = ErrorType<unknown>

/**
 * @summary Physical Snapshot Metric Stats Api
 */
export const usePhysicalSnapshotMetricStats = <TData = Awaited<ReturnType<typeof physicalSnapshotMetricStats>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof physicalSnapshotMetricStats>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPhysicalSnapshotMetricStatsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Metric List Api
 */
export const metricList = (
    params?: MetricListParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<MetricSchema[]>(
      {url: `/api/fitness/metrics/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getMetricListQueryKey = (params?: MetricListParams,) => {
    return [`/api/fitness/metrics/`, ...(params ? [params]: [])] as const;
    }

    
export const getMetricListQueryOptions = <TData = Awaited<ReturnType<typeof metricList>>, TError = ErrorType<unknown>>(params?: MetricListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof metricList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMetricListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof metricList>>> = ({ signal }) => metricList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof metricList>>, TError, TData> & { queryKey: QueryKey }
}

export type MetricListQueryResult = NonNullable<Awaited<ReturnType<typeof metricList>>>
export type MetricListQueryError = ErrorType<unknown>

/**
 * @summary Metric List Api
 */
export const useMetricList = <TData = Awaited<ReturnType<typeof metricList>>, TError = ErrorType<unknown>>(
 params?: MetricListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof metricList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getMetricListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Metric Create Api
 */
export const metricCreate = (
    metricCreateInputSeraliser: MetricCreateInputSeraliser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<MetricSchema>(
      {url: `/api/fitness/metrics/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: metricCreateInputSeraliser
    },
      options);
    }
  


export const getMetricCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof metricCreate>>, TError,{data: MetricCreateInputSeraliser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof metricCreate>>, TError,{data: MetricCreateInputSeraliser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof metricCreate>>, {data: MetricCreateInputSeraliser}> = (props) => {
          const {data} = props ?? {};

          return  metricCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type MetricCreateMutationResult = NonNullable<Awaited<ReturnType<typeof metricCreate>>>
    export type MetricCreateMutationBody = MetricCreateInputSeraliser
    export type MetricCreateMutationError = ErrorType<unknown>

    /**
 * @summary Metric Create Api
 */
export const useMetricCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof metricCreate>>, TError,{data: MetricCreateInputSeraliser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof metricCreate>>,
        TError,
        {data: MetricCreateInputSeraliser},
        TContext
      > => {

      const mutationOptions = getMetricCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Metric Update Api
 */
export const metricUpdate = (
    id: string,
    metricUpdateInputSeraliser: MetricUpdateInputSeraliser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<MetricSchema>(
      {url: `/api/fitness/metrics/${id}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: metricUpdateInputSeraliser
    },
      options);
    }
  


export const getMetricUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof metricUpdate>>, TError,{id: string;data: MetricUpdateInputSeraliser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof metricUpdate>>, TError,{id: string;data: MetricUpdateInputSeraliser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof metricUpdate>>, {id: string;data: MetricUpdateInputSeraliser}> = (props) => {
          const {id,data} = props ?? {};

          return  metricUpdate(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type MetricUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof metricUpdate>>>
    export type MetricUpdateMutationBody = MetricUpdateInputSeraliser
    export type MetricUpdateMutationError = ErrorType<unknown>

    /**
 * @summary Metric Update Api
 */
export const useMetricUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof metricUpdate>>, TError,{id: string;data: MetricUpdateInputSeraliser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof metricUpdate>>,
        TError,
        {id: string;data: MetricUpdateInputSeraliser},
        TContext
      > => {

      const mutationOptions = getMetricUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary User List Api
 */
export const userAdminList = (
    params?: UserAdminListParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PagedUserAdminSchema>(
      {url: `/api/control-panel/users/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getUserAdminListQueryKey = (params?: UserAdminListParams,) => {
    return [`/api/control-panel/users/`, ...(params ? [params]: [])] as const;
    }

    
export const getUserAdminListQueryOptions = <TData = Awaited<ReturnType<typeof userAdminList>>, TError = ErrorType<unknown>>(params?: UserAdminListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userAdminList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserAdminListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userAdminList>>> = ({ signal }) => userAdminList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userAdminList>>, TError, TData> & { queryKey: QueryKey }
}

export type UserAdminListQueryResult = NonNullable<Awaited<ReturnType<typeof userAdminList>>>
export type UserAdminListQueryError = ErrorType<unknown>

/**
 * @summary User List Api
 */
export const useUserAdminList = <TData = Awaited<ReturnType<typeof userAdminList>>, TError = ErrorType<unknown>>(
 params?: UserAdminListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userAdminList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserAdminListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary User Create Api
 */
export const userAdminCreate = (
    userAdminCreateInputSerialiser: UserAdminCreateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<UserAdminSchema>(
      {url: `/api/control-panel/users/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userAdminCreateInputSerialiser
    },
      options);
    }
  


export const getUserAdminCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userAdminCreate>>, TError,{data: UserAdminCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof userAdminCreate>>, TError,{data: UserAdminCreateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userAdminCreate>>, {data: UserAdminCreateInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  userAdminCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UserAdminCreateMutationResult = NonNullable<Awaited<ReturnType<typeof userAdminCreate>>>
    export type UserAdminCreateMutationBody = UserAdminCreateInputSerialiser
    export type UserAdminCreateMutationError = ErrorType<unknown>

    /**
 * @summary User Create Api
 */
export const useUserAdminCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userAdminCreate>>, TError,{data: UserAdminCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof userAdminCreate>>,
        TError,
        {data: UserAdminCreateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getUserAdminCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary User Get Api
 */
export const userAdminGet = (
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<UserAdminSchema>(
      {url: `/api/control-panel/users/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getUserAdminGetQueryKey = (id: string,) => {
    return [`/api/control-panel/users/${id}`] as const;
    }

    
export const getUserAdminGetQueryOptions = <TData = Awaited<ReturnType<typeof userAdminGet>>, TError = ErrorType<unknown>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userAdminGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserAdminGetQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userAdminGet>>> = ({ signal }) => userAdminGet(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userAdminGet>>, TError, TData> & { queryKey: QueryKey }
}

export type UserAdminGetQueryResult = NonNullable<Awaited<ReturnType<typeof userAdminGet>>>
export type UserAdminGetQueryError = ErrorType<unknown>

/**
 * @summary User Get Api
 */
export const useUserAdminGet = <TData = Awaited<ReturnType<typeof userAdminGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userAdminGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserAdminGetQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary User Update Api
 */
export const userAdminUpdate = (
    id: string,
    userAdminUpdateInputSerialiser: UserAdminUpdateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<UserAdminSchema>(
      {url: `/api/control-panel/users/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: userAdminUpdateInputSerialiser
    },
      options);
    }
  


export const getUserAdminUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userAdminUpdate>>, TError,{id: string;data: UserAdminUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof userAdminUpdate>>, TError,{id: string;data: UserAdminUpdateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userAdminUpdate>>, {id: string;data: UserAdminUpdateInputSerialiser}> = (props) => {
          const {id,data} = props ?? {};

          return  userAdminUpdate(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UserAdminUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof userAdminUpdate>>>
    export type UserAdminUpdateMutationBody = UserAdminUpdateInputSerialiser
    export type UserAdminUpdateMutationError = ErrorType<unknown>

    /**
 * @summary User Update Api
 */
export const useUserAdminUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userAdminUpdate>>, TError,{id: string;data: UserAdminUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof userAdminUpdate>>,
        TError,
        {id: string;data: UserAdminUpdateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getUserAdminUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary User Delete Api
 */
export const userAdminDelete = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/control-panel/users/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getUserAdminDeleteMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userAdminDelete>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof userAdminDelete>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userAdminDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  userAdminDelete(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UserAdminDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof userAdminDelete>>>
    
    export type UserAdminDeleteMutationError = ErrorType<unknown>

    /**
 * @summary User Delete Api
 */
export const useUserAdminDelete = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userAdminDelete>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof userAdminDelete>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getUserAdminDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Group List Api
 */
export const groupList = (
    params?: GroupListParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GroupSchema[]>(
      {url: `/api/control-panel/groups/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGroupListQueryKey = (params?: GroupListParams,) => {
    return [`/api/control-panel/groups/`, ...(params ? [params]: [])] as const;
    }

    
export const getGroupListQueryOptions = <TData = Awaited<ReturnType<typeof groupList>>, TError = ErrorType<unknown>>(params?: GroupListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof groupList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGroupListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof groupList>>> = ({ signal }) => groupList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof groupList>>, TError, TData> & { queryKey: QueryKey }
}

export type GroupListQueryResult = NonNullable<Awaited<ReturnType<typeof groupList>>>
export type GroupListQueryError = ErrorType<unknown>

/**
 * @summary Group List Api
 */
export const useGroupList = <TData = Awaited<ReturnType<typeof groupList>>, TError = ErrorType<unknown>>(
 params?: GroupListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof groupList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGroupListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Group Create Api
 */
export const groupCreate = (
    groupCreateInputSerialiser: GroupCreateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<GroupSchema>(
      {url: `/api/control-panel/groups/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: groupCreateInputSerialiser
    },
      options);
    }
  


export const getGroupCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof groupCreate>>, TError,{data: GroupCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof groupCreate>>, TError,{data: GroupCreateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof groupCreate>>, {data: GroupCreateInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  groupCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GroupCreateMutationResult = NonNullable<Awaited<ReturnType<typeof groupCreate>>>
    export type GroupCreateMutationBody = GroupCreateInputSerialiser
    export type GroupCreateMutationError = ErrorType<unknown>

    /**
 * @summary Group Create Api
 */
export const useGroupCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof groupCreate>>, TError,{data: GroupCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof groupCreate>>,
        TError,
        {data: GroupCreateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getGroupCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Group List For User Api
 */
export const groupListForUser = (
    userId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GroupSchema[]>(
      {url: `/api/control-panel/groups/users/${userId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGroupListForUserQueryKey = (userId: string,) => {
    return [`/api/control-panel/groups/users/${userId}`] as const;
    }

    
export const getGroupListForUserQueryOptions = <TData = Awaited<ReturnType<typeof groupListForUser>>, TError = ErrorType<unknown>>(userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof groupListForUser>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGroupListForUserQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof groupListForUser>>> = ({ signal }) => groupListForUser(userId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof groupListForUser>>, TError, TData> & { queryKey: QueryKey }
}

export type GroupListForUserQueryResult = NonNullable<Awaited<ReturnType<typeof groupListForUser>>>
export type GroupListForUserQueryError = ErrorType<unknown>

/**
 * @summary Group List For User Api
 */
export const useGroupListForUser = <TData = Awaited<ReturnType<typeof groupListForUser>>, TError = ErrorType<unknown>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof groupListForUser>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGroupListForUserQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Group Get Api
 */
export const groupGet = (
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GroupSchema>(
      {url: `/api/control-panel/groups/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGroupGetQueryKey = (id: string,) => {
    return [`/api/control-panel/groups/${id}`] as const;
    }

    
export const getGroupGetQueryOptions = <TData = Awaited<ReturnType<typeof groupGet>>, TError = ErrorType<unknown>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof groupGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGroupGetQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof groupGet>>> = ({ signal }) => groupGet(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof groupGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GroupGetQueryResult = NonNullable<Awaited<ReturnType<typeof groupGet>>>
export type GroupGetQueryError = ErrorType<unknown>

/**
 * @summary Group Get Api
 */
export const useGroupGet = <TData = Awaited<ReturnType<typeof groupGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof groupGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGroupGetQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Group Update Api
 */
export const groupUpdate = (
    id: string,
    groupUpdateInputSerialiser: GroupUpdateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<GroupSchema>(
      {url: `/api/control-panel/groups/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: groupUpdateInputSerialiser
    },
      options);
    }
  


export const getGroupUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof groupUpdate>>, TError,{id: string;data: GroupUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof groupUpdate>>, TError,{id: string;data: GroupUpdateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof groupUpdate>>, {id: string;data: GroupUpdateInputSerialiser}> = (props) => {
          const {id,data} = props ?? {};

          return  groupUpdate(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GroupUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof groupUpdate>>>
    export type GroupUpdateMutationBody = GroupUpdateInputSerialiser
    export type GroupUpdateMutationError = ErrorType<unknown>

    /**
 * @summary Group Update Api
 */
export const useGroupUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof groupUpdate>>, TError,{id: string;data: GroupUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof groupUpdate>>,
        TError,
        {id: string;data: GroupUpdateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getGroupUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Group Update Permissions Api
 */
export const groupUpdatePermissions = (
    id: string,
    groupUpdatePermissionsInputSerialiser: GroupUpdatePermissionsInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<GroupSchema>(
      {url: `/api/control-panel/groups/${id}/permissions`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: groupUpdatePermissionsInputSerialiser
    },
      options);
    }
  


export const getGroupUpdatePermissionsMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof groupUpdatePermissions>>, TError,{id: string;data: GroupUpdatePermissionsInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof groupUpdatePermissions>>, TError,{id: string;data: GroupUpdatePermissionsInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof groupUpdatePermissions>>, {id: string;data: GroupUpdatePermissionsInputSerialiser}> = (props) => {
          const {id,data} = props ?? {};

          return  groupUpdatePermissions(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GroupUpdatePermissionsMutationResult = NonNullable<Awaited<ReturnType<typeof groupUpdatePermissions>>>
    export type GroupUpdatePermissionsMutationBody = GroupUpdatePermissionsInputSerialiser
    export type GroupUpdatePermissionsMutationError = ErrorType<unknown>

    /**
 * @summary Group Update Permissions Api
 */
export const useGroupUpdatePermissions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof groupUpdatePermissions>>, TError,{id: string;data: GroupUpdatePermissionsInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof groupUpdatePermissions>>,
        TError,
        {id: string;data: GroupUpdatePermissionsInputSerialiser},
        TContext
      > => {

      const mutationOptions = getGroupUpdatePermissionsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Group Update Members Api
 */
export const groupUpdateUsers = (
    id: string,
    groupUpdateUsersInputSerialiser: GroupUpdateUsersInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<GroupSchema>(
      {url: `/api/control-panel/groups/${id}/users`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: groupUpdateUsersInputSerialiser
    },
      options);
    }
  


export const getGroupUpdateUsersMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof groupUpdateUsers>>, TError,{id: string;data: GroupUpdateUsersInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof groupUpdateUsers>>, TError,{id: string;data: GroupUpdateUsersInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof groupUpdateUsers>>, {id: string;data: GroupUpdateUsersInputSerialiser}> = (props) => {
          const {id,data} = props ?? {};

          return  groupUpdateUsers(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GroupUpdateUsersMutationResult = NonNullable<Awaited<ReturnType<typeof groupUpdateUsers>>>
    export type GroupUpdateUsersMutationBody = GroupUpdateUsersInputSerialiser
    export type GroupUpdateUsersMutationError = ErrorType<unknown>

    /**
 * @summary Group Update Members Api
 */
export const useGroupUpdateUsers = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof groupUpdateUsers>>, TError,{id: string;data: GroupUpdateUsersInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof groupUpdateUsers>>,
        TError,
        {id: string;data: GroupUpdateUsersInputSerialiser},
        TContext
      > => {

      const mutationOptions = getGroupUpdateUsersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Group Delete Api
 */
export const groupDelete = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/control-panel/groups/${id}/`, method: 'DELETE'
    },
      options);
    }
  


export const getGroupDeleteMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof groupDelete>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof groupDelete>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof groupDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  groupDelete(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GroupDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof groupDelete>>>
    
    export type GroupDeleteMutationError = ErrorType<unknown>

    /**
 * @summary Group Delete Api
 */
export const useGroupDelete = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof groupDelete>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof groupDelete>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getGroupDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Permission Admin List Api
 */
export const permissionAdminList = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PermissionSchema[]>(
      {url: `/api/control-panel/permissions/`, method: 'GET', signal
    },
      options);
    }
  

export const getPermissionAdminListQueryKey = () => {
    return [`/api/control-panel/permissions/`] as const;
    }

    
export const getPermissionAdminListQueryOptions = <TData = Awaited<ReturnType<typeof permissionAdminList>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof permissionAdminList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPermissionAdminListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof permissionAdminList>>> = ({ signal }) => permissionAdminList(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof permissionAdminList>>, TError, TData> & { queryKey: QueryKey }
}

export type PermissionAdminListQueryResult = NonNullable<Awaited<ReturnType<typeof permissionAdminList>>>
export type PermissionAdminListQueryError = ErrorType<unknown>

/**
 * @summary Permission Admin List Api
 */
export const usePermissionAdminList = <TData = Awaited<ReturnType<typeof permissionAdminList>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof permissionAdminList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPermissionAdminListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Team List Api
 */
export const teamList = (
    params?: TeamListParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PagedTeamSchema>(
      {url: `/api/control-panel/teams/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getTeamListQueryKey = (params?: TeamListParams,) => {
    return [`/api/control-panel/teams/`, ...(params ? [params]: [])] as const;
    }

    
export const getTeamListQueryOptions = <TData = Awaited<ReturnType<typeof teamList>>, TError = ErrorType<unknown>>(params?: TeamListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof teamList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTeamListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof teamList>>> = ({ signal }) => teamList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof teamList>>, TError, TData> & { queryKey: QueryKey }
}

export type TeamListQueryResult = NonNullable<Awaited<ReturnType<typeof teamList>>>
export type TeamListQueryError = ErrorType<unknown>

/**
 * @summary Team List Api
 */
export const useTeamList = <TData = Awaited<ReturnType<typeof teamList>>, TError = ErrorType<unknown>>(
 params?: TeamListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof teamList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getTeamListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Team Create Api
 */
export const teamCreate = (
    teamCreateInputSerialiser: TeamCreateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<TeamSchema>(
      {url: `/api/control-panel/teams/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: teamCreateInputSerialiser
    },
      options);
    }
  


export const getTeamCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof teamCreate>>, TError,{data: TeamCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof teamCreate>>, TError,{data: TeamCreateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof teamCreate>>, {data: TeamCreateInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  teamCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TeamCreateMutationResult = NonNullable<Awaited<ReturnType<typeof teamCreate>>>
    export type TeamCreateMutationBody = TeamCreateInputSerialiser
    export type TeamCreateMutationError = ErrorType<unknown>

    /**
 * @summary Team Create Api
 */
export const useTeamCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof teamCreate>>, TError,{data: TeamCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof teamCreate>>,
        TError,
        {data: TeamCreateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getTeamCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Team Get Api
 */
export const teamGet = (
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<TeamSchema>(
      {url: `/api/control-panel/teams/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getTeamGetQueryKey = (id: string,) => {
    return [`/api/control-panel/teams/${id}`] as const;
    }

    
export const getTeamGetQueryOptions = <TData = Awaited<ReturnType<typeof teamGet>>, TError = ErrorType<unknown>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof teamGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTeamGetQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof teamGet>>> = ({ signal }) => teamGet(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof teamGet>>, TError, TData> & { queryKey: QueryKey }
}

export type TeamGetQueryResult = NonNullable<Awaited<ReturnType<typeof teamGet>>>
export type TeamGetQueryError = ErrorType<unknown>

/**
 * @summary Team Get Api
 */
export const useTeamGet = <TData = Awaited<ReturnType<typeof teamGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof teamGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getTeamGetQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Team Update Api
 */
export const teamUpdate = (
    id: string,
    teamUpdateInputSerialiser: TeamUpdateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<TeamSchema>(
      {url: `/api/control-panel/teams/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: teamUpdateInputSerialiser
    },
      options);
    }
  


export const getTeamUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof teamUpdate>>, TError,{id: string;data: TeamUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof teamUpdate>>, TError,{id: string;data: TeamUpdateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof teamUpdate>>, {id: string;data: TeamUpdateInputSerialiser}> = (props) => {
          const {id,data} = props ?? {};

          return  teamUpdate(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TeamUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof teamUpdate>>>
    export type TeamUpdateMutationBody = TeamUpdateInputSerialiser
    export type TeamUpdateMutationError = ErrorType<unknown>

    /**
 * @summary Team Update Api
 */
export const useTeamUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof teamUpdate>>, TError,{id: string;data: TeamUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof teamUpdate>>,
        TError,
        {id: string;data: TeamUpdateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getTeamUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Team Delete Api
 */
export const teamDelete = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/control-panel/teams/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getTeamDeleteMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof teamDelete>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof teamDelete>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof teamDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  teamDelete(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type TeamDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof teamDelete>>>
    
    export type TeamDeleteMutationError = ErrorType<unknown>

    /**
 * @summary Team Delete Api
 */
export const useTeamDelete = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof teamDelete>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof teamDelete>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getTeamDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Secret List Api
 */
export const secretList = (
    teamId: string,
    params?: SecretListParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PagedSecretSchema>(
      {url: `/api/control-panel/secrets/${teamId}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getSecretListQueryKey = (teamId: string,
    params?: SecretListParams,) => {
    return [`/api/control-panel/secrets/${teamId}`, ...(params ? [params]: [])] as const;
    }

    
export const getSecretListQueryOptions = <TData = Awaited<ReturnType<typeof secretList>>, TError = ErrorType<unknown>>(teamId: string,
    params?: SecretListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof secretList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSecretListQueryKey(teamId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof secretList>>> = ({ signal }) => secretList(teamId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(teamId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof secretList>>, TError, TData> & { queryKey: QueryKey }
}

export type SecretListQueryResult = NonNullable<Awaited<ReturnType<typeof secretList>>>
export type SecretListQueryError = ErrorType<unknown>

/**
 * @summary Secret List Api
 */
export const useSecretList = <TData = Awaited<ReturnType<typeof secretList>>, TError = ErrorType<unknown>>(
 teamId: string,
    params?: SecretListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof secretList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSecretListQueryOptions(teamId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Secret Create Api
 */
export const secretCreate = (
    teamId: string,
    secretCreateInputSerialiser: SecretCreateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<SecretSchema>(
      {url: `/api/control-panel/secrets/${teamId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: secretCreateInputSerialiser
    },
      options);
    }
  


export const getSecretCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof secretCreate>>, TError,{teamId: string;data: SecretCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof secretCreate>>, TError,{teamId: string;data: SecretCreateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof secretCreate>>, {teamId: string;data: SecretCreateInputSerialiser}> = (props) => {
          const {teamId,data} = props ?? {};

          return  secretCreate(teamId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SecretCreateMutationResult = NonNullable<Awaited<ReturnType<typeof secretCreate>>>
    export type SecretCreateMutationBody = SecretCreateInputSerialiser
    export type SecretCreateMutationError = ErrorType<unknown>

    /**
 * @summary Secret Create Api
 */
export const useSecretCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof secretCreate>>, TError,{teamId: string;data: SecretCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof secretCreate>>,
        TError,
        {teamId: string;data: SecretCreateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getSecretCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Secret Update Api
 */
export const secretUpdate = (
    teamId: string,
    id: string,
    secretUpdateInputSerialiser: SecretUpdateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<SecretSchema>(
      {url: `/api/control-panel/secrets/${teamId}/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: secretUpdateInputSerialiser
    },
      options);
    }
  


export const getSecretUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof secretUpdate>>, TError,{teamId: string;id: string;data: SecretUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof secretUpdate>>, TError,{teamId: string;id: string;data: SecretUpdateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof secretUpdate>>, {teamId: string;id: string;data: SecretUpdateInputSerialiser}> = (props) => {
          const {teamId,id,data} = props ?? {};

          return  secretUpdate(teamId,id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SecretUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof secretUpdate>>>
    export type SecretUpdateMutationBody = SecretUpdateInputSerialiser
    export type SecretUpdateMutationError = ErrorType<unknown>

    /**
 * @summary Secret Update Api
 */
export const useSecretUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof secretUpdate>>, TError,{teamId: string;id: string;data: SecretUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof secretUpdate>>,
        TError,
        {teamId: string;id: string;data: SecretUpdateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getSecretUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Secret Delete Api
 */
export const secretDelete = (
    teamId: string,
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/control-panel/secrets/${teamId}/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getSecretDeleteMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof secretDelete>>, TError,{teamId: string;id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof secretDelete>>, TError,{teamId: string;id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof secretDelete>>, {teamId: string;id: string}> = (props) => {
          const {teamId,id} = props ?? {};

          return  secretDelete(teamId,id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SecretDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof secretDelete>>>
    
    export type SecretDeleteMutationError = ErrorType<unknown>

    /**
 * @summary Secret Delete Api
 */
export const useSecretDelete = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof secretDelete>>, TError,{teamId: string;id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof secretDelete>>,
        TError,
        {teamId: string;id: string},
        TContext
      > => {

      const mutationOptions = getSecretDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Run Task Api
 */
export const runTask = (
    taskName: string,
    bodySchema: BodySchema,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/control-panel/tasks/${taskName}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: bodySchema
    },
      options);
    }
  


export const getRunTaskMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof runTask>>, TError,{taskName: string;data: BodySchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof runTask>>, TError,{taskName: string;data: BodySchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof runTask>>, {taskName: string;data: BodySchema}> = (props) => {
          const {taskName,data} = props ?? {};

          return  runTask(taskName,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RunTaskMutationResult = NonNullable<Awaited<ReturnType<typeof runTask>>>
    export type RunTaskMutationBody = BodySchema
    export type RunTaskMutationError = ErrorType<unknown>

    /**
 * @summary Run Task Api
 */
export const useRunTask = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof runTask>>, TError,{taskName: string;data: BodySchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof runTask>>,
        TError,
        {taskName: string;data: BodySchema},
        TContext
      > => {

      const mutationOptions = getRunTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Auth Token List For User Api
 */
export const authTokenListForUser = (
    userId: string,
    params?: AuthTokenListForUserParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PagedAuthTokenMetadataSchema>(
      {url: `/api/control-panel/auth-tokens/users/${userId}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getAuthTokenListForUserQueryKey = (userId: string,
    params?: AuthTokenListForUserParams,) => {
    return [`/api/control-panel/auth-tokens/users/${userId}`, ...(params ? [params]: [])] as const;
    }

    
export const getAuthTokenListForUserQueryOptions = <TData = Awaited<ReturnType<typeof authTokenListForUser>>, TError = ErrorType<unknown>>(userId: string,
    params?: AuthTokenListForUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof authTokenListForUser>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuthTokenListForUserQueryKey(userId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof authTokenListForUser>>> = ({ signal }) => authTokenListForUser(userId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof authTokenListForUser>>, TError, TData> & { queryKey: QueryKey }
}

export type AuthTokenListForUserQueryResult = NonNullable<Awaited<ReturnType<typeof authTokenListForUser>>>
export type AuthTokenListForUserQueryError = ErrorType<unknown>

/**
 * @summary Auth Token List For User Api
 */
export const useAuthTokenListForUser = <TData = Awaited<ReturnType<typeof authTokenListForUser>>, TError = ErrorType<unknown>>(
 userId: string,
    params?: AuthTokenListForUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof authTokenListForUser>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAuthTokenListForUserQueryOptions(userId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Auth Token Revolk All For User Api
 */
export const authTokenRevolkAllForUser = (
    userId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/control-panel/auth-tokens/users/${userId}/revolk/all/`, method: 'POST'
    },
      options);
    }
  


export const getAuthTokenRevolkAllForUserMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authTokenRevolkAllForUser>>, TError,{userId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof authTokenRevolkAllForUser>>, TError,{userId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authTokenRevolkAllForUser>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  authTokenRevolkAllForUser(userId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AuthTokenRevolkAllForUserMutationResult = NonNullable<Awaited<ReturnType<typeof authTokenRevolkAllForUser>>>
    
    export type AuthTokenRevolkAllForUserMutationError = ErrorType<unknown>

    /**
 * @summary Auth Token Revolk All For User Api
 */
export const useAuthTokenRevolkAllForUser = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authTokenRevolkAllForUser>>, TError,{userId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof authTokenRevolkAllForUser>>,
        TError,
        {userId: string},
        TContext
      > => {

      const mutationOptions = getAuthTokenRevolkAllForUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Mfa Team Configuration Update Api
 */
export const mfaTeamConfigurationUpdate = (
    id: string,
    mFATeamConfigurationUpdateInputSerialiser: MFATeamConfigurationUpdateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<MFATeamConfigurationSchema>(
      {url: `/api/control-panel/mfa-configuration/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: mFATeamConfigurationUpdateInputSerialiser
    },
      options);
    }
  


export const getMfaTeamConfigurationUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof mfaTeamConfigurationUpdate>>, TError,{id: string;data: MFATeamConfigurationUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof mfaTeamConfigurationUpdate>>, TError,{id: string;data: MFATeamConfigurationUpdateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof mfaTeamConfigurationUpdate>>, {id: string;data: MFATeamConfigurationUpdateInputSerialiser}> = (props) => {
          const {id,data} = props ?? {};

          return  mfaTeamConfigurationUpdate(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type MfaTeamConfigurationUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof mfaTeamConfigurationUpdate>>>
    export type MfaTeamConfigurationUpdateMutationBody = MFATeamConfigurationUpdateInputSerialiser
    export type MfaTeamConfigurationUpdateMutationError = ErrorType<unknown>

    /**
 * @summary Mfa Team Configuration Update Api
 */
export const useMfaTeamConfigurationUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof mfaTeamConfigurationUpdate>>, TError,{id: string;data: MFATeamConfigurationUpdateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof mfaTeamConfigurationUpdate>>,
        TError,
        {id: string;data: MFATeamConfigurationUpdateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getMfaTeamConfigurationUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Workspace Get Api
 */
export const workspaceAdminGet = (
    teamId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<WorkspaceSchema>(
      {url: `/api/control-panel/workspace/${teamId}`, method: 'GET', signal
    },
      options);
    }
  

export const getWorkspaceAdminGetQueryKey = (teamId: string,) => {
    return [`/api/control-panel/workspace/${teamId}`] as const;
    }

    
export const getWorkspaceAdminGetQueryOptions = <TData = Awaited<ReturnType<typeof workspaceAdminGet>>, TError = ErrorType<unknown>>(teamId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof workspaceAdminGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWorkspaceAdminGetQueryKey(teamId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof workspaceAdminGet>>> = ({ signal }) => workspaceAdminGet(teamId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(teamId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof workspaceAdminGet>>, TError, TData> & { queryKey: QueryKey }
}

export type WorkspaceAdminGetQueryResult = NonNullable<Awaited<ReturnType<typeof workspaceAdminGet>>>
export type WorkspaceAdminGetQueryError = ErrorType<unknown>

/**
 * @summary Workspace Get Api
 */
export const useWorkspaceAdminGet = <TData = Awaited<ReturnType<typeof workspaceAdminGet>>, TError = ErrorType<unknown>>(
 teamId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof workspaceAdminGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWorkspaceAdminGetQueryOptions(teamId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Workspace Update Api
 */
export const workspaceAdminUpdate = (
    teamId: string,
    workspaceInputSerialiser: WorkspaceInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<WorkspaceSchema>(
      {url: `/api/control-panel/workspace/${teamId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: workspaceInputSerialiser
    },
      options);
    }
  


export const getWorkspaceAdminUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof workspaceAdminUpdate>>, TError,{teamId: string;data: WorkspaceInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof workspaceAdminUpdate>>, TError,{teamId: string;data: WorkspaceInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof workspaceAdminUpdate>>, {teamId: string;data: WorkspaceInputSerialiser}> = (props) => {
          const {teamId,data} = props ?? {};

          return  workspaceAdminUpdate(teamId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type WorkspaceAdminUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof workspaceAdminUpdate>>>
    export type WorkspaceAdminUpdateMutationBody = WorkspaceInputSerialiser
    export type WorkspaceAdminUpdateMutationError = ErrorType<unknown>

    /**
 * @summary Workspace Update Api
 */
export const useWorkspaceAdminUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof workspaceAdminUpdate>>, TError,{teamId: string;data: WorkspaceInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof workspaceAdminUpdate>>,
        TError,
        {teamId: string;data: WorkspaceInputSerialiser},
        TContext
      > => {

      const mutationOptions = getWorkspaceAdminUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Event Log List For User Api
 */
export const eventLogListForUser = (
    userId: string,
    params?: EventLogListForUserParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PagedEventLogSchema>(
      {url: `/api/control-panel/audit/event-logs/users/${userId}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getEventLogListForUserQueryKey = (userId: string,
    params?: EventLogListForUserParams,) => {
    return [`/api/control-panel/audit/event-logs/users/${userId}`, ...(params ? [params]: [])] as const;
    }

    
export const getEventLogListForUserQueryOptions = <TData = Awaited<ReturnType<typeof eventLogListForUser>>, TError = ErrorType<unknown>>(userId: string,
    params?: EventLogListForUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof eventLogListForUser>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEventLogListForUserQueryKey(userId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof eventLogListForUser>>> = ({ signal }) => eventLogListForUser(userId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof eventLogListForUser>>, TError, TData> & { queryKey: QueryKey }
}

export type EventLogListForUserQueryResult = NonNullable<Awaited<ReturnType<typeof eventLogListForUser>>>
export type EventLogListForUserQueryError = ErrorType<unknown>

/**
 * @summary Event Log List For User Api
 */
export const useEventLogListForUser = <TData = Awaited<ReturnType<typeof eventLogListForUser>>, TError = ErrorType<unknown>>(
 userId: string,
    params?: EventLogListForUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof eventLogListForUser>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getEventLogListForUserQueryOptions(userId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * This endpoint may be considered unsafe as there is no checking that the user has access to the
underlying resource. Therefore, the `EVENT_LOG_VIEW_PERMISSION` permission should only be
assigned to admins.
 * @summary Event Log List For Resource Api
 */
export const eventLogListForResource = (
    resourceName: string,
    resourceId: string,
    params?: EventLogListForResourceParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PagedEventLogSchema>(
      {url: `/api/control-panel/audit/event-logs/resources/${resourceName}/${resourceId}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getEventLogListForResourceQueryKey = (resourceName: string,
    resourceId: string,
    params?: EventLogListForResourceParams,) => {
    return [`/api/control-panel/audit/event-logs/resources/${resourceName}/${resourceId}`, ...(params ? [params]: [])] as const;
    }

    
export const getEventLogListForResourceQueryOptions = <TData = Awaited<ReturnType<typeof eventLogListForResource>>, TError = ErrorType<unknown>>(resourceName: string,
    resourceId: string,
    params?: EventLogListForResourceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof eventLogListForResource>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEventLogListForResourceQueryKey(resourceName,resourceId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof eventLogListForResource>>> = ({ signal }) => eventLogListForResource(resourceName,resourceId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(resourceName && resourceId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof eventLogListForResource>>, TError, TData> & { queryKey: QueryKey }
}

export type EventLogListForResourceQueryResult = NonNullable<Awaited<ReturnType<typeof eventLogListForResource>>>
export type EventLogListForResourceQueryError = ErrorType<unknown>

/**
 * @summary Event Log List For Resource Api
 */
export const useEventLogListForResource = <TData = Awaited<ReturnType<typeof eventLogListForResource>>, TError = ErrorType<unknown>>(
 resourceName: string,
    resourceId: string,
    params?: EventLogListForResourceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof eventLogListForResource>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getEventLogListForResourceQueryOptions(resourceName,resourceId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Auth Saml Metadata Api
 */
export const authSamlMetadata = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/control-panel/auth/saml/metadata`, method: 'POST'
    },
      options);
    }
  


export const getAuthSamlMetadataMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authSamlMetadata>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof authSamlMetadata>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authSamlMetadata>>, void> = () => {
          

          return  authSamlMetadata(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AuthSamlMetadataMutationResult = NonNullable<Awaited<ReturnType<typeof authSamlMetadata>>>
    
    export type AuthSamlMetadataMutationError = ErrorType<unknown>

    /**
 * @summary Auth Saml Metadata Api
 */
export const useAuthSamlMetadata = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authSamlMetadata>>, TError,void, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof authSamlMetadata>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getAuthSamlMetadataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Integration List Api
 */
export const integrationList = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<IntegrationSchema[]>(
      {url: `/api/control-panel/integrations/`, method: 'GET', signal
    },
      options);
    }
  

export const getIntegrationListQueryKey = () => {
    return [`/api/control-panel/integrations/`] as const;
    }

    
export const getIntegrationListQueryOptions = <TData = Awaited<ReturnType<typeof integrationList>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof integrationList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getIntegrationListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof integrationList>>> = ({ signal }) => integrationList(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof integrationList>>, TError, TData> & { queryKey: QueryKey }
}

export type IntegrationListQueryResult = NonNullable<Awaited<ReturnType<typeof integrationList>>>
export type IntegrationListQueryError = ErrorType<unknown>

/**
 * @summary Integration List Api
 */
export const useIntegrationList = <TData = Awaited<ReturnType<typeof integrationList>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof integrationList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getIntegrationListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Integration Get Api
 */
export const integrationGet = (
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<IntegrationSchema>(
      {url: `/api/control-panel/integrations/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getIntegrationGetQueryKey = (id: string,) => {
    return [`/api/control-panel/integrations/${id}`] as const;
    }

    
export const getIntegrationGetQueryOptions = <TData = Awaited<ReturnType<typeof integrationGet>>, TError = ErrorType<unknown>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof integrationGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getIntegrationGetQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof integrationGet>>> = ({ signal }) => integrationGet(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof integrationGet>>, TError, TData> & { queryKey: QueryKey }
}

export type IntegrationGetQueryResult = NonNullable<Awaited<ReturnType<typeof integrationGet>>>
export type IntegrationGetQueryError = ErrorType<unknown>

/**
 * @summary Integration Get Api
 */
export const useIntegrationGet = <TData = Awaited<ReturnType<typeof integrationGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof integrationGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getIntegrationGetQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Integration Instance List Api
 */
export const integrationInstanceList = (
    params?: IntegrationInstanceListParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PagedIntegrationInstanceSchema>(
      {url: `/api/control-panel/integrations/instances/instances`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getIntegrationInstanceListQueryKey = (params?: IntegrationInstanceListParams,) => {
    return [`/api/control-panel/integrations/instances/instances`, ...(params ? [params]: [])] as const;
    }

    
export const getIntegrationInstanceListQueryOptions = <TData = Awaited<ReturnType<typeof integrationInstanceList>>, TError = ErrorType<unknown>>(params?: IntegrationInstanceListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof integrationInstanceList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getIntegrationInstanceListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof integrationInstanceList>>> = ({ signal }) => integrationInstanceList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof integrationInstanceList>>, TError, TData> & { queryKey: QueryKey }
}

export type IntegrationInstanceListQueryResult = NonNullable<Awaited<ReturnType<typeof integrationInstanceList>>>
export type IntegrationInstanceListQueryError = ErrorType<unknown>

/**
 * @summary Integration Instance List Api
 */
export const useIntegrationInstanceList = <TData = Awaited<ReturnType<typeof integrationInstanceList>>, TError = ErrorType<unknown>>(
 params?: IntegrationInstanceListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof integrationInstanceList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getIntegrationInstanceListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Integration Instance Create Api
 */
export const integrationInstanceCreate = (
    integrationInstanceCreateInputSerialiser: IntegrationInstanceCreateInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IntegrationInstanceSchema>(
      {url: `/api/control-panel/integrations/instances/instances`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: integrationInstanceCreateInputSerialiser
    },
      options);
    }
  


export const getIntegrationInstanceCreateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof integrationInstanceCreate>>, TError,{data: IntegrationInstanceCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof integrationInstanceCreate>>, TError,{data: IntegrationInstanceCreateInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof integrationInstanceCreate>>, {data: IntegrationInstanceCreateInputSerialiser}> = (props) => {
          const {data} = props ?? {};

          return  integrationInstanceCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type IntegrationInstanceCreateMutationResult = NonNullable<Awaited<ReturnType<typeof integrationInstanceCreate>>>
    export type IntegrationInstanceCreateMutationBody = IntegrationInstanceCreateInputSerialiser
    export type IntegrationInstanceCreateMutationError = ErrorType<unknown>

    /**
 * @summary Integration Instance Create Api
 */
export const useIntegrationInstanceCreate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof integrationInstanceCreate>>, TError,{data: IntegrationInstanceCreateInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof integrationInstanceCreate>>,
        TError,
        {data: IntegrationInstanceCreateInputSerialiser},
        TContext
      > => {

      const mutationOptions = getIntegrationInstanceCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Integration Instance Get Api
 */
export const integrationInstanceGet = (
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<IntegrationInstanceSchema>(
      {url: `/api/control-panel/integrations/instances/instances/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getIntegrationInstanceGetQueryKey = (id: string,) => {
    return [`/api/control-panel/integrations/instances/instances/${id}`] as const;
    }

    
export const getIntegrationInstanceGetQueryOptions = <TData = Awaited<ReturnType<typeof integrationInstanceGet>>, TError = ErrorType<unknown>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof integrationInstanceGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getIntegrationInstanceGetQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof integrationInstanceGet>>> = ({ signal }) => integrationInstanceGet(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof integrationInstanceGet>>, TError, TData> & { queryKey: QueryKey }
}

export type IntegrationInstanceGetQueryResult = NonNullable<Awaited<ReturnType<typeof integrationInstanceGet>>>
export type IntegrationInstanceGetQueryError = ErrorType<unknown>

/**
 * @summary Integration Instance Get Api
 */
export const useIntegrationInstanceGet = <TData = Awaited<ReturnType<typeof integrationInstanceGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof integrationInstanceGet>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getIntegrationInstanceGetQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Integration Instance Delete Api
 */
export const integrationInstanceDelete = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/control-panel/integrations/instances/instances/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getIntegrationInstanceDeleteMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof integrationInstanceDelete>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof integrationInstanceDelete>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof integrationInstanceDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  integrationInstanceDelete(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type IntegrationInstanceDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof integrationInstanceDelete>>>
    
    export type IntegrationInstanceDeleteMutationError = ErrorType<unknown>

    /**
 * @summary Integration Instance Delete Api
 */
export const useIntegrationInstanceDelete = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof integrationInstanceDelete>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof integrationInstanceDelete>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getIntegrationInstanceDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Integration Instance Update Api
 */
export const integrationInstanceUpdateDetails = (
    id: string,
    integrationInstanceUpdateDetailsInputSerialiser: IntegrationInstanceUpdateDetailsInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IntegrationInstanceSchema>(
      {url: `/api/control-panel/integrations/instances/instances/${id}/details`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: integrationInstanceUpdateDetailsInputSerialiser
    },
      options);
    }
  


export const getIntegrationInstanceUpdateDetailsMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof integrationInstanceUpdateDetails>>, TError,{id: string;data: IntegrationInstanceUpdateDetailsInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof integrationInstanceUpdateDetails>>, TError,{id: string;data: IntegrationInstanceUpdateDetailsInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof integrationInstanceUpdateDetails>>, {id: string;data: IntegrationInstanceUpdateDetailsInputSerialiser}> = (props) => {
          const {id,data} = props ?? {};

          return  integrationInstanceUpdateDetails(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type IntegrationInstanceUpdateDetailsMutationResult = NonNullable<Awaited<ReturnType<typeof integrationInstanceUpdateDetails>>>
    export type IntegrationInstanceUpdateDetailsMutationBody = IntegrationInstanceUpdateDetailsInputSerialiser
    export type IntegrationInstanceUpdateDetailsMutationError = ErrorType<unknown>

    /**
 * @summary Integration Instance Update Api
 */
export const useIntegrationInstanceUpdateDetails = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof integrationInstanceUpdateDetails>>, TError,{id: string;data: IntegrationInstanceUpdateDetailsInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof integrationInstanceUpdateDetails>>,
        TError,
        {id: string;data: IntegrationInstanceUpdateDetailsInputSerialiser},
        TContext
      > => {

      const mutationOptions = getIntegrationInstanceUpdateDetailsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Integration Instance Update Config Api
 */
export const integrationInstanceUpdateConfig = (
    id: string,
    integrationInstanceUpdateConfigInputSerialiser: IntegrationInstanceUpdateConfigInputSerialiser,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<IntegrationInstanceSchema>(
      {url: `/api/control-panel/integrations/instances/instances/${id}/config`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: integrationInstanceUpdateConfigInputSerialiser
    },
      options);
    }
  


export const getIntegrationInstanceUpdateConfigMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof integrationInstanceUpdateConfig>>, TError,{id: string;data: IntegrationInstanceUpdateConfigInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof integrationInstanceUpdateConfig>>, TError,{id: string;data: IntegrationInstanceUpdateConfigInputSerialiser}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof integrationInstanceUpdateConfig>>, {id: string;data: IntegrationInstanceUpdateConfigInputSerialiser}> = (props) => {
          const {id,data} = props ?? {};

          return  integrationInstanceUpdateConfig(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type IntegrationInstanceUpdateConfigMutationResult = NonNullable<Awaited<ReturnType<typeof integrationInstanceUpdateConfig>>>
    export type IntegrationInstanceUpdateConfigMutationBody = IntegrationInstanceUpdateConfigInputSerialiser
    export type IntegrationInstanceUpdateConfigMutationError = ErrorType<unknown>

    /**
 * @summary Integration Instance Update Config Api
 */
export const useIntegrationInstanceUpdateConfig = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof integrationInstanceUpdateConfig>>, TError,{id: string;data: IntegrationInstanceUpdateConfigInputSerialiser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof integrationInstanceUpdateConfig>>,
        TError,
        {id: string;data: IntegrationInstanceUpdateConfigInputSerialiser},
        TContext
      > => {

      const mutationOptions = getIntegrationInstanceUpdateConfigMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Integration Instance Manually Run Api
 */
export const integrationInstanceManuallyRun = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<void>(
      {url: `/api/control-panel/integrations/instances/instances/${id}/manually-run`, method: 'POST'
    },
      options);
    }
  


export const getIntegrationInstanceManuallyRunMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof integrationInstanceManuallyRun>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof integrationInstanceManuallyRun>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof integrationInstanceManuallyRun>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  integrationInstanceManuallyRun(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type IntegrationInstanceManuallyRunMutationResult = NonNullable<Awaited<ReturnType<typeof integrationInstanceManuallyRun>>>
    
    export type IntegrationInstanceManuallyRunMutationError = ErrorType<unknown>

    /**
 * @summary Integration Instance Manually Run Api
 */
export const useIntegrationInstanceManuallyRun = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof integrationInstanceManuallyRun>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof integrationInstanceManuallyRun>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getIntegrationInstanceManuallyRunMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Integration Instance Execution List Api
 */
export const integrationInstanceExecutionListForIntegrationInstance = (
    integrationInstanceId: string,
    params?: IntegrationInstanceExecutionListForIntegrationInstanceParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PagedIntegrationInstanceExecutionSchema>(
      {url: `/api/control-panel/integrations/instances/executions/${integrationInstanceId}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getIntegrationInstanceExecutionListForIntegrationInstanceQueryKey = (integrationInstanceId: string,
    params?: IntegrationInstanceExecutionListForIntegrationInstanceParams,) => {
    return [`/api/control-panel/integrations/instances/executions/${integrationInstanceId}`, ...(params ? [params]: [])] as const;
    }

    
export const getIntegrationInstanceExecutionListForIntegrationInstanceQueryOptions = <TData = Awaited<ReturnType<typeof integrationInstanceExecutionListForIntegrationInstance>>, TError = ErrorType<unknown>>(integrationInstanceId: string,
    params?: IntegrationInstanceExecutionListForIntegrationInstanceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof integrationInstanceExecutionListForIntegrationInstance>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getIntegrationInstanceExecutionListForIntegrationInstanceQueryKey(integrationInstanceId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof integrationInstanceExecutionListForIntegrationInstance>>> = ({ signal }) => integrationInstanceExecutionListForIntegrationInstance(integrationInstanceId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(integrationInstanceId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof integrationInstanceExecutionListForIntegrationInstance>>, TError, TData> & { queryKey: QueryKey }
}

export type IntegrationInstanceExecutionListForIntegrationInstanceQueryResult = NonNullable<Awaited<ReturnType<typeof integrationInstanceExecutionListForIntegrationInstance>>>
export type IntegrationInstanceExecutionListForIntegrationInstanceQueryError = ErrorType<unknown>

/**
 * @summary Integration Instance Execution List Api
 */
export const useIntegrationInstanceExecutionListForIntegrationInstance = <TData = Awaited<ReturnType<typeof integrationInstanceExecutionListForIntegrationInstance>>, TError = ErrorType<unknown>>(
 integrationInstanceId: string,
    params?: IntegrationInstanceExecutionListForIntegrationInstanceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof integrationInstanceExecutionListForIntegrationInstance>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getIntegrationInstanceExecutionListForIntegrationInstanceQueryOptions(integrationInstanceId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Search Api
 */
export const controlPanelSearch = (
    params: ControlPanelSearchParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<PagedAnnotated>(
      {url: `/api/control-panel/search/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getControlPanelSearchQueryKey = (params: ControlPanelSearchParams,) => {
    return [`/api/control-panel/search/`, ...(params ? [params]: [])] as const;
    }

    
export const getControlPanelSearchQueryOptions = <TData = Awaited<ReturnType<typeof controlPanelSearch>>, TError = ErrorType<unknown>>(params: ControlPanelSearchParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof controlPanelSearch>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getControlPanelSearchQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof controlPanelSearch>>> = ({ signal }) => controlPanelSearch(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof controlPanelSearch>>, TError, TData> & { queryKey: QueryKey }
}

export type ControlPanelSearchQueryResult = NonNullable<Awaited<ReturnType<typeof controlPanelSearch>>>
export type ControlPanelSearchQueryError = ErrorType<unknown>

/**
 * @summary Search Api
 */
export const useControlPanelSearch = <TData = Awaited<ReturnType<typeof controlPanelSearch>>, TError = ErrorType<unknown>>(
 params: ControlPanelSearchParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof controlPanelSearch>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getControlPanelSearchQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}
