import React from "react";

import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import {
  createRoutesFromChildren,
  matchRoutes, useLocation,
  useNavigationType
} from "react-router-dom";

import App from "./App";

import "./index.css";

Sentry.init({
  dsn: "https://acb9beaa4d33fc03d8ada5fcaca435f5@o318909.ingest.us.sentry.io/4507811783245824",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/solar.wilkinsontechnologies.co.uk\.com/],
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.PROD,
});


ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
