import React from "react";

import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { Spinner } from "@/components/elements";
import { ErrorFallback } from "@/components/layout";
import { Toaster } from "@/components/ui/toaster";
import { AuthProvider } from "@/features/authentication";
import { queryClient } from "@/lib/query";

const LoadingFallback = () => {
  return (
    <div className="page-center">
      <Spinner />
    </div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense fallback={<LoadingFallback />}>
      <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
        <QueryClientProvider client={queryClient}>
          {import.meta.env.MODE === "development" && (
            <ReactQueryDevtools position="bottom-right" />
          )}
          <Toaster />
          <AuthProvider fallback={<LoadingFallback />}>{children}</AuthProvider>
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    </React.Suspense>
  );
};
