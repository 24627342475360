import { memo } from "react";

import type { LucideIcon } from "lucide-react";

import { cn } from "@/lib/utils";

export interface HeaderProps {
  title?: string;
  subtitle?: string;
  icon?: LucideIcon;
  children?: React.ReactNode;
  sticky?: boolean;
}

export const Header = memo(
  ({ title, subtitle, icon, children = [], sticky = false }: HeaderProps) => {
    const Icon = icon;

    return (
      <div className={cn("w-full bg-white py-2 px-3 border-b", sticky && "sticky top-0 z-10")}>
        <div className="sm:flex sm:items-center sm:justify-between">
          {Icon && (
            <div className="border h-10 w-10 mr-2 rounded-sm bg-gray-100 flex items-center justify-center">
              <Icon className="h-5 w-5" />
            </div>
          )}
          <div className="min-w-0 flex-1">
            <h2 className="text-md font-normal tracking-tight">{title}</h2>
            <p className="text-xs font-light text-muted-foreground">
              {subtitle}
            </p>
          </div>
          {children && (
            <div className="flex space-x-2">
              {children}
            </div>
          )}
        </div>
      </div>
    );
  },
);

Header.displayName = "Header";
