import type { UseFormReturn } from "react-hook-form";

import { ErrorAlert } from "./ErrorAlert";

type FormErrorProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>;
  className?: string;
};

export const FormError = ({ form, className }: FormErrorProps) => {
  const errors = form.formState.errors;

  if (Object.entries(errors).length == 0) {
    return null;
  }

  const rootError = errors.root?.message;

  return (
    <ErrorAlert className={className} errorMessage={rootError ?? "Please correct the errors below"} />
  );
};
