import { useEffect } from "react";

import { Card } from "@/components/ui/card";
import { getDocumentTitle } from "@/utils/document";

type AuthLayoutProps = {
  title: string;
  heading: string;
  subheading?: string;
  children: React.ReactNode;
};

export const AuthLayout = ({
  title,
  heading,
  subheading,
  children,
}: AuthLayoutProps) => {
  useEffect(() => {
    document.title = getDocumentTitle(title);
  }, [title]);

  return (
    <div className="min-h-screen bg-white">
      <div className="container flex h-screen w-screen flex-col items-center justify-center">
        <div className="mx-auto">{/* <Logo className="h-24" /> */}</div>
        <div className="mx-auto flex w-full flex-col justify-center sm:w-[350px]">
          <Card className="px-6 py-8 space-y-6">
            <div className="flex flex-col space-y-1 text-center">
              <h1 className="text-xl font-normal tracking-tight">{heading}</h1>
              <p className="text-xs font-light text-muted-foreground">
                {subheading}
              </p>
            </div>
            {children}
          </Card>
        </div>
      </div>
    </div>
  );
};
