import type { AxiosRequestConfig, AxiosResponse } from "axios";
import Axios from "axios";

import type { ExceptionSchema } from "@/api";
import { getUserMeQueryKey } from "@/api";

import { queryClient } from "./query";

// baseURL: import.meta.env.PROD ? "http://api.solar.localhost/" : "http://localhost:5173/",

export const AXIOS_INSTANCE = Axios.create({
  baseURL: import.meta.env.PROD ? "https://api.solar.wilkinsontechnologies.co.uk/" : "http://localhost:5173/",
  withCredentials: true,
  // Remove [] from name of array query parameters.
  paramsSerializer: {
    indexes: null,
  },
});

export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<T> => {
  const source = Axios.CancelToken.source();

  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then((response) => response.data)
    .catch(error => {
      const errorResponse = error.response as AxiosResponse;
      const errorType = errorResponse.data?.type;
      if (errorType === "token" || errorType === "mfa") {
        queryClient.invalidateQueries([getUserMeQueryKey()])
      }
      throw errorResponse;
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (promise as any).cancel = () => {
    source.cancel("Query was cancelled");
  };

  return promise as Promise<T>;
};

export type ErrorType = AxiosResponse<ExceptionSchema>;
