import type { UseFormReturn } from "react-hook-form";

import type { ExceptionSchema } from "@/api";


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setFormError = (form: UseFormReturn<any>, e: any) => {
    const formFields = form.watch();
    // let hasFieldError = false;

    const error = e?.data as unknown as ExceptionSchema;

    if (error?.fields) {
        Object.entries(error.fields).map(([fieldName, error]) => {
            if (Object.prototype.hasOwnProperty.call(formFields, fieldName)) {
                // hasFieldError = true;
                form.setError(fieldName, { message: error.join(" ") })
            }
        });
    }

    form.setError("root", { message: error.detail })
}