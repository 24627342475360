import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";

import { Alert, AlertDescription } from "../ui/alert";

type ErrorAlertProps = {
  errorMessage: string;
  className?: string;
}

export const ErrorAlert = ({ errorMessage, className }: ErrorAlertProps) => {
  return (
    <Alert className={cn("font-light", className)} variant="destructive">
      <AlertDescription className="flex space-x-4">
        <ExclamationTriangleIcon className="my-auto mr-2 h-4 w-4" />
        {errorMessage ?? "Please correct the error below"}
      </AlertDescription>
    </Alert>
  );
}
